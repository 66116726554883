import { EventEmitter } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
} from '@angular/core';
import { User } from '@angular/fire/auth';
import { MatButton } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { isNil } from 'lodash';

import { ApiMembership } from '@one-percent/shared';

import { AvatarComponent } from '../../avatar/avatar.component';
import { CookieConsentService } from '../../cookie-consent.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrl: './user-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatButton,
    MatMenuTrigger,
    AvatarComponent,
    MatIcon,
    MatMenu,
    MatMenuItem,
    MatDivider,
    RouterLink,
  ],
})
export class UserMenuComponent {
  @Input() authState?: User | null;
  @Input() memberships?: ApiMembership[] | null;
  @Input() selectedMembership?: ApiMembership | null;
  @Input() showLegal = true;

  @Output() readonly selectedMembershipChange =
    new EventEmitter<ApiMembership>();
  @Output() readonly changePassword = new EventEmitter<void>();
  @Output() readonly signOut = new EventEmitter<void>();

  constructor(readonly cookieConsent: CookieConsentService) {}

  /** @internal */
  get displayName() {
    return (
      this.selectedMembership?.currentUser.name ||
      this.selectedMembership?.currentUser.email ||
      this.authState?.email ||
      'Guest'
    );
  }

  /** @internal */
  get isLoadingMemberships() {
    return isNil(this.memberships);
  }
}
