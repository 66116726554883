import { ArrayValues } from 'type-fest';

import { ApiAddress } from './address';
import { ApiDonation } from './donation';
import { ApiFile } from './file';
import { ApiFiscalYear } from './fiscal-year';
import { ApiUser } from './user';

export interface ApiAccountAutocompleteQuery {
  [param: string]: string | undefined;

  term: string;
  accountType: AccountType;
}

export interface ApiAccountAutocompleteResults {
  results: ApiAccountAutocompleteResult[];
  totalResults: number;
}

export interface ApiAccountAutocompleteResult extends ApiAccountStub {
  status: 'active' | 'eligible';
}

export interface ApiAccountSearchQuery {
  [param: string]: string | undefined;

  industry?: string;
  impactArea?: string;
  impactCountry?: string;
  location?: string;
  accountType?: AccountType;
  q?: string;
  volunteer?: string;

  page?: string;
  pageSize?: string;

  bounds?: string;

  favoritedBy?: string;
}

export interface ApiAccountSearchResult {
  id: string;
  uri: string;
  name: string;
  logoUrl?: string;
  nameHighlighted?: string;
  type: AccountType;
  location?: { lat: number; lng: number };
  impactAreas?: string[];
  industry?: string;
  address?: string;
  snippet?: string;
  featuredGuideId?: string;
}

export interface ApiAccountSearchResults {
  results: ApiAccountSearchResult[];
  totalResults: number;
}

export interface ApiAccountStub {
  id: string;
  type: AccountType;
  name: string;
  logoUrl?: string;
}

export interface ApiAccountProfileStub {
  uri?: string;
  name: string;
  logoUrl?: string;
}

export const accountTypes = ['business', 'nonprofit'] as const;
export type AccountType = ArrayValues<typeof accountTypes>;

export const accountStatuses = ['active', 'late'] as const;
export type AccountStatus = ArrayValues<typeof accountStatuses>;

export const accountStatusesExtended = [
  ...accountStatuses,
  'eligible',
  'inactive',
] as const;
export type AccountStatusExtended = ArrayValues<typeof accountStatusesExtended>;

export interface ApiBusinessAttributes {
  industry?: string;
}

export interface ApiNonprofitAttributes {
  impactAreas?: string[];
  impactCountries?: string[];
  missionStatement?: string;
  programDetails?: string;
  programs?: string[];
  signedAgreementAt?: string;
  questionnaireCompletedAt?: string;
}

export interface ApiSocialAttributes {
  facebook?: string;
  twitter?: string;
  instagram?: string;
  linkedin?: string;
  youtube?: string;
}

export interface ApiAccountDetailAttributes
  extends ApiBusinessAttributes,
    ApiNonprofitAttributes,
    ApiSocialAttributes {
  uri: string;
  currency?: string;
  activationDate?: string;
  public: boolean;
  summary?: string;
  description?: string;
  imageUrls: string[];
  videoUrl?: string;
  address?: ApiAddress;
  addressPublic: boolean;
  email?: string;
  phone?: string;
  website?: string;
  owner?: {
    name: string;
    email: string;
  };
  flags?: string[];
}

/**
 * Public / private profile displayed in directory
 */

export type ApiAccountProfile =
  | ApiAccountPublicProfile
  | ApiAccountPrivateProfile;

export interface ApiAccountPublicProfile
  extends ApiAccountStub,
    ApiAccountDetailAttributes {
  uri: string;
  name: string;
  logoUrl?: string;
  type: AccountType;
  public: true;
  supporters: ApiAccountProfileStub[];
  supports: ApiAccountProfileStub[];
  featuredGuideId?: string;
}

export interface ApiAccountPrivateProfile {
  uri: string;
  name: string;
  logoUrl?: string;
  type: AccountType;
  public: false;
}

export interface ApiAccountInfo extends ApiAccountStub {
  status: AccountStatusExtended;
  programs?: string[];
}

/**
 * Account details only available to users part of the organization
 */

export interface ApiAccountDetails
  extends ApiAccountStub,
    ApiAccountDetailAttributes {
  status: AccountStatus;
  users: ApiUser[];
  certificationContactId?: string;
  fiscalYears: ApiFiscalYear[];
  donations: ApiDonation[];
  supporters: ApiAccountSupport[];
  hideQuestionnaire: boolean;
}

export interface ApiAccountSupport {
  account: ApiAccountProfileStub;
  lastDonatedAt: string;
}

export interface ApiUpdateAccountArgs {
  id: string;
  currency?: string;
  public?: boolean;
  logo?: ApiFile | null;
  email?: string | null;
  website?: string | null;
  summary?: string | null;
  description?: string | null;
  missionStatement?: string | null;
  programDetails?: string | null;
  images?: ApiFile[];
  videoUrl?: string | null;
  phone?: string | null;
  address?: ApiAddress | null;
  addressPublic?: boolean;
  facebook?: string | null;
  instagram?: string | null;
  twitter?: string | null;
  linkedin?: string | null;
  youtube?: string | null;
  impactAreas?: string[];
  impactCountries?: string[];
  industry?: string | null;
  certificationContactId?: string;
}

export interface ApiUpdateAccountResult {
  logoUrl?: string;
  imageUrls: string[];
}
