<form class="auth-card" [formGroup]="form" (submit)="submit()" novalidate>
  <div class="auth-card__back">
    <a mat-button (click)="returnToSignIn.emit()">
      <mat-icon>navigate_before</mat-icon> Sign In
    </a>
  </div>

  <h1 class="mat-headline-4">Reset Password</h1>

  <div class="auth-card__content">
    @if (email) {
      <mat-form-field>
        <mat-label>Email</mat-label>
        <input
          class="text-base"
          matInput
          type="text"
          [value]="email"
          disabled
        />
      </mat-form-field>
    }

    <mat-form-field hideRequiredMarker>
      <mat-label>New Password</mat-label>
      <input
        matInput
        type="password"
        formControlName="newPassword"
        autocomplete="new-password"
      />
      <mat-hint class="text-secondary">{{ passwordHint }}</mat-hint>
    </mat-form-field>

    <button
      type="submit"
      mat-raised-button
      color="primary"
      [disabled]="submitting$ | async"
    >
      {{ (submitting$ | async) ? 'Submitting…' : 'Set Password' }}
    </button>
  </div>

  <div class="auth-card__footer text-warn">
    {{ errorMessage$ | async }}
  </div>
</form>
