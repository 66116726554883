import { Injectable } from '@angular/core';
import { NavigationError, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

/**
 * Handles unexpected error while navigating, by redirecting to the 404 page.
 *
 * e.g. if the user directly enters a URL with parentheses, it causes a `noMatchError`.
 *
 * NOTE: Errors are still uncaught and also handled by ErrorHandler,
 * this just prevents a blank page from being shown.
 */
@Injectable({ providedIn: 'root' })
export class NavErrorHandler {
  constructor(router: Router) {
    router.events
      .pipe(filter((event) => event instanceof NavigationError))
      .subscribe(() => {
        void router.navigate(['/404'], { skipLocationChange: true });
      });
  }
}
