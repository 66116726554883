import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-splash-layout',
  templateUrl: './splash-layout.component.html',
  styleUrl: './splash-layout.component.scss',
  standalone: true,
  imports: [RouterLink],
})
export class SplashLayoutComponent {}
