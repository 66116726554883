import { CdkVirtualScrollableElement } from '@angular/cdk/scrolling';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatRipple } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import {
  MatSidenav,
  MatSidenavContainer,
  MatSidenavContent,
} from '@angular/material/sidenav';
import { Router, RouterOutlet } from '@angular/router';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';

import { ApiMembership } from '@one-percent/shared';

import { AccountNavService } from './account/account-nav.service';
import { AuthService } from './auth/auth.service';
import { ChangePasswordDialog } from './auth/change-password/change-password.dialog';
import { AccountService } from './data-access/account/account.service';
import { AnalyticsService } from './shared/analytics/analytics.service';
import { HeaderComponent } from './shared/nav/header/header.component';
import { MobileHeaderComponent } from './shared/nav/header/mobile-header.component';
import { SidenavComponent } from './shared/nav/sidenav/sidenav.component';
import { ScrollTopOnNavDirective } from './utility/scroll-top-on-nav.directive';

const today = new Date();

const HIDE_SLACK_BANNER_KEY = 'hideSlackBanner';

@Component({
  selector: 'app-layout',
  templateUrl: './app-layout.component.html',
  styleUrl: './app-layout.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatSidenavContainer,
    MatSidenav,
    SidenavComponent,
    MatSidenavContent,
    ScrollTopOnNavDirective,
    CdkVirtualScrollableElement,
    MobileHeaderComponent,
    HeaderComponent,
    RouterOutlet,
    AsyncPipe,
    MatIconButton,
    MatIcon,
    MatRipple,
  ],
})
export class AppLayoutComponent {
  readonly memberships$ = this.accountService.all$;

  readonly navItems$ = this.accountNavService.items$;
  readonly resources$ = this.accountNavService.resources$;

  readonly authState$ = this.auth.authState$;
  readonly isImpersonating$ = this.auth.isImpersonating$;

  readonly selectedMembership$ = combineLatest([
    this.authState$,
    this.accountService.selected$,
  ]).pipe(
    // filter out `null` membership while user is logged in
    // to prevent flickering when changing memberships
    filter(([authState, membership]) => !authState || !!membership),
    map(([authState, membership]) => (authState ? membership : null)),
    shareReplay(1),
  );

  private readonly hideSlackBannerSubject = new BehaviorSubject(
    !!localStorage.getItem(HIDE_SLACK_BANNER_KEY),
  );

  readonly showSlackBanner$ = combineLatest([
    this.authState$,
    this.hideSlackBannerSubject,
  ]).pipe(
    map(
      ([authState, hideSlackBanner]) =>
        !!authState &&
        !hideSlackBanner &&
        // only show Slack banner for August 2024
        today >= new Date(2024, 7, 1) &&
        today < new Date(2024, 8, 1),
    ),
    shareReplay(1),
  );

  constructor(
    private accountNavService: AccountNavService,
    private accountService: AccountService,
    private analytics: AnalyticsService,
    private auth: AuthService,
    private dialog: MatDialog,
    private router: Router,
  ) {}

  openChangePasswordDialog(): void {
    ChangePasswordDialog.open(this.dialog);
  }

  async signIn(): Promise<void> {
    await this.router.navigate(['/auth']);
  }

  async signOut(): Promise<void> {
    await this.router.navigate(['/auth']);
    await this.auth.signOut();
    this.analytics.reset();
  }

  selectAccount({ id }: ApiMembership): void {
    this.accountService.selectAccount(id);

    if (!this.router.url.startsWith('/account')) {
      void this.router.navigate(['/account']);
    }
  }

  dismissSlackBanner() {
    this.hideSlackBannerSubject.next(true);
    localStorage.setItem(HIDE_SLACK_BANNER_KEY, '1');
  }
}
