import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, interval, switchMap } from 'rxjs';

import { StatusApiService } from '../data-access/status-api.service';

interface Status {
  message: string;
}

export const maintenancePath = 'maintenance';

/**
 * Interval in milliseconds to check API status while not in maintenance mode.
 */
const checkInterval = 5 * 60_000; // 5 minutes
/**
 * Interval in milliseconds to check API status while maintenance mode is enabled.
 * (this should be more frequent, so we can return to the app ASAP)
 */
const enabledCheckInterval = 60_000; // 1 minute

@Injectable({ providedIn: 'root' })
export class MaintenanceService {
  private readonly statusSubject = new BehaviorSubject<Status | undefined>(
    undefined,
  );

  readonly status$ = this.statusSubject.asObservable();

  constructor(
    private router: Router,
    private statusApi: StatusApiService,
  ) {
    // check status on an interval
    // (interceptor will automatically disable maintenance mode when a request succeeds)
    this.status$
      .pipe(
        switchMap((enabled) =>
          interval(enabled ? enabledCheckInterval : checkInterval),
        ),
        switchMap(() => this.statusApi.checkStatus()),
      )
      .subscribe();
  }

  enable(status: Status) {
    this.statusSubject.next(status);
  }

  disable() {
    this.statusSubject.next(undefined);
  }

  createMaintenanceUrl(returnTo?: string) {
    return this.router.createUrlTree([maintenancePath], {
      queryParams: { returnTo },
    });
  }

  isMaintenanceUrl(url: string) {
    return url.startsWith(`/${maintenancePath}`);
  }
}
