import { ErrorHandler, inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { AnalyticsService } from '../shared/analytics/analytics.service';
import { AuthService } from './auth.service';

export const customTokenGuard: CanActivateFn = async (route) => {
  const analytics = inject(AnalyticsService);
  const auth = inject(AuthService);
  const errorHandler = inject(ErrorHandler);
  const router = inject(Router);

  if (typeof route.queryParams['token'] === 'string') {
    try {
      await auth.signInWithCustomToken(route.queryParams['token']);
      return router.createUrlTree(['/account']);
    } catch (error) {
      errorHandler.handleError(error);
      await auth.signOut();
      analytics.reset();
    }
  }

  return true;
};
