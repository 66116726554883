import { Component, HostBinding, Input } from '@angular/core';

import { isNotNil } from '@one-percent/shared';

import { getRandomSecondaryColor } from '../../../theme/secondary-color';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrl: './avatar.component.scss',
  standalone: true,
  imports: [],
})
export class AvatarComponent {
  @Input() imageUrl?: string | null;

  @Input() set name(name: string | null | undefined) {
    if (name) {
      this.#theme = `theme-${getRandomSecondaryColor(name)}`;
      this.initial = name?.[0]?.toUpperCase();
    } else {
      this.#theme = undefined;
      this.initial = undefined;
    }
  }

  #theme?: string;

  @Input() size?: 'small' | 'medium' | 'large';

  @HostBinding('class.disabled') @Input() disabled = false;

  /** @internal */
  @HostBinding('class') get cssClass(): string {
    return [this.#theme, this.size].filter(isNotNil).join(' ');
  }

  /** @internal */
  @HostBinding('class.has-image') get hasImage(): boolean {
    return !!this.imageUrl && !this.imageFailedToLoad;
  }

  /** @internal */
  initial?: string;

  /** @internal */
  imageFailedToLoad = false;
}
