import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const auth = inject(AuthService);

  if (req.url.startsWith(environment.apiUrl)) {
    return auth.authState$.pipe(
      take(1),
      switchMap((authState) => authState?.getIdToken() ?? of(null)),
      switchMap((token) =>
        next(
          token
            ? req.clone({
                headers: req.headers.set('Authorization', `Bearer ${token}`),
              })
            : req,
        ),
      ),
    );
  } else {
    return next(req);
  }
};
