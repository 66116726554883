<mat-toolbar>
  <div class="layout-container flex items-center w-full">
    <a class="header-logo" routerLink="/">
      <img src="assets/logo.svg" />
    </a>
    @if (!hideNav) {
      <div class="ms-auto flex items-center gap-2" [@fadeIn]>
        <a
          mat-button
          routerLink="/"
          [class.active]="nav.isDirectoryRouteActive$ | async"
        >
          Directory
        </a>

        @if (authState) {
          <button
            mat-button
            [matMenuTriggerFor]="resourceMenu"
            [disabled]="resources?.length === 0"
          >
            Resources
          </button>
          <mat-menu #resourceMenu="matMenu">
            <app-resource-menu
              [resources]="resources"
              [selectedMembership]="selectedMembership"
            />
          </mat-menu>

          <a mat-button [routerLink]="['/account']" routerLinkActive="active">
            Account
          </a>
        }

        @if (authState) {
          <app-user-menu
            [authState]="authState"
            [memberships]="memberships"
            [selectedMembership]="selectedMembership"
            (selectedMembershipChange)="selectedMembershipChange.emit($event)"
            (changePassword)="changePassword.emit()"
            (signOut)="signOut.emit()"
          />
        } @else {
          <button mat-stroked-button (click)="signIn.emit()">Sign In</button>

          <button
            class="text-secondary"
            mat-icon-button
            [matMenuTriggerFor]="privacyMenu"
          >
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #privacyMenu="matMenu" xPosition="before">
            <a
              mat-menu-item
              href="https://onepercentfortheplanet.org/privacy-policy"
              target="_blank"
            >
              <mat-icon>policy</mat-icon>
              Privacy policy
            </a>
            <a
              mat-menu-item
              href="https://onepercentfortheplanet.org/terms-of-use"
              target="_blank"
            >
              <mat-icon>check_circle</mat-icon>
              Terms of use
            </a>
            <button mat-menu-item (click)="cookieConsent.openPreferences()">
              <mat-icon>cookie</mat-icon>
              Cookie preferences
            </button>
          </mat-menu>
        }
      </div>
    }
  </div>
</mat-toolbar>
