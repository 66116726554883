import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

/**
 * Scrolls to the top of the element after router navigation.
 *
 * Useful for elements such as `<mat-sidenav-content>` that have their own scrollbar
 * that can't be restored by the Angular router using `scrollPositionRestoration`.
 */
@Directive({
  selector: '[appScrollTopOnNav]',
  standalone: true,
})
export class ScrollTopOnNavDirective implements OnInit, OnDestroy {
  private subscription?: Subscription;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private router: Router,
  ) {}

  ngOnInit() {
    this.subscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.elementRef.nativeElement.scrollTo(0, 0);
      });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
