<mat-toolbar class="px-0 bg-white">
  @if (sidenav) {
    <a mat-icon-button (click)="sidenav.toggle()">
      <mat-icon>menu</mat-icon>
    </a>
  }
  <div class="flex justify-center">
    <a routerLink="/">
      <img src="assets/logo.svg" class="h-8" />
    </a>
  </div>

  <div class="ms-auto">
    @if (authState) {
      <app-user-menu
        [showLegal]="false"
        [authState]="authState"
        [memberships]="memberships"
        [selectedMembership]="selectedMembership"
        (selectedMembershipChange)="selectedMembershipChange.emit($event)"
        (changePassword)="changePassword.emit()"
        (signOut)="signOut.emit()"
      />
    } @else {
      <button class="me-2" mat-button color="primary" (click)="signIn.emit()">
        Sign In
      </button>
    }
  </div>
</mat-toolbar>
