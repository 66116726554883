import { Injectable } from '@angular/core';
import mixpanel, { Query } from 'mixpanel-browser';

import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  constructor() {
    mixpanel.init(environment.mixpanelToken, {
      debug: !environment.production,
    });
  }

  /**
   * Should be used when a user logs in.
   * https://help.mixpanel.com/hc/en-us/articles/115004497803
   */
  identifyUser(uniqueId: string) {
    mixpanel.identify(uniqueId);
  }

  /**
   * Should be used when a new user signs up.
   * https://help.mixpanel.com/hc/en-us/articles/115004497803
   */
  aliasUser(uniqueId: string) {
    mixpanel.alias(uniqueId);
  }

  /**
   * Tracks an event.
   * https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpaneltrack
   */
  track(eventName: string, properties?: Record<string, unknown>) {
    mixpanel.track(eventName, properties);
  }

  /**
   * Track clicks on a set of document elements.
   * https://docs.mixpanel.com/docs/tracking/reference/javascript-full-api-reference#mixpaneltrack_links
   */
  trackLinks(
    query: Query,
    eventName: string,
    properties?: Record<string, unknown>,
  ) {
    mixpanel.track_links(query, eventName, properties);
  }

  /**
   * Track form submissions.
   * https://github.com/mixpanel/mixpanel-js/blob/master/doc/readme.io/javascript-full-api-reference.md#mixpaneltrack_forms
   */
  trackForms(
    query: Query,
    eventName: string,
    properties?: Record<string, unknown>,
  ) {
    mixpanel.track_forms(query, eventName, properties);
  }

  /**
   * Clears super properties and generates a new random distinct_id for this instance.
   * Useful for clearing data when a user logs out.
   */
  reset() {
    mixpanel.reset();
  }
}
