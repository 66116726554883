import { animate, style, transition, trigger } from '@angular/animations';

/**
 * Short animation to avoid the content "popping in".
 * (i.e. menu items depending on auth state)
 */
export const fadeInAnimation = trigger('fadeIn', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('100ms ease-in', style({ opacity: 1 })),
  ]),
]);
