import { UppyFile } from '@uppy/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

export class NewFile {
  readonly isNew = true;

  readonly uploadProgress$ = new BehaviorSubject<number | undefined>(undefined);
  readonly uploadComplete$ = new ReplaySubject<{ key: string }>(1);
  readonly uploadError$ = new BehaviorSubject<Error | undefined>(undefined);

  imageUrl?: string;

  get id(): string {
    return this.uppyFile.id;
  }

  get name(): string {
    return this.uppyFile.name;
  }

  get blob(): Blob {
    return this.uppyFile.data;
  }

  get hasUploadError() {
    return !!this.uploadError$.value;
  }

  constructor(readonly uppyFile: UppyFile) {}
}

export function isNewFile(value: unknown): value is NewFile {
  return value instanceof NewFile;
}
