import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

import { State, StateIconComponent } from '../state-icon/state-icon.component';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrl: './message.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, MatIcon, StateIconComponent, MatButton],
})
export class MessageComponent {
  @HostBinding('class')
  get classNames(): string {
    return `${this.type} mode-${this.mode} ${this.border ? 'border' : ''}`;
  }

  @Input()
  type: 'message' | State = 'message';

  @Input()
  size: 'medium' | 'large' = 'medium';

  @Input()
  mode: 'default' | 'alert' = 'default';

  @Input() icon?: string | null;
  @Input() title?: string | null;
  @Input() html?: string | null;
  @Input() message?: string | null;
  @Input() actionLabel?: string | null;
  @Input() border = true;
  @Output() action = new EventEmitter<void>();
}
