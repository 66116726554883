import { Routes } from '@angular/router';

import { AppLayoutComponent } from './app-layout.component';
import { AuthComponent } from './auth/auth.component';
import { authGuard } from './auth/auth.guard';
import { customTokenGuard } from './auth/custom-token.guard';
import { VerifyEmailComponent } from './auth/verify-email/verify-email.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { maintenanceGuard } from './maintenance/maintenance.guard';
import { maintenancePath } from './maintenance/maintenance.service';
import { NotFoundComponent } from './not-found/not-found.component';

export const routes: Routes = [
  // redirect legacy auth routes
  { path: 'login', redirectTo: 'auth' },
  { path: 'forgot', redirectTo: 'auth/forgot' },
  { path: 'invite/:email', redirectTo: 'auth/invite/:email' },

  { path: 'auth', component: AuthComponent },
  { path: 'auth/:mode', component: AuthComponent },
  { path: 'auth/:mode/:email', component: AuthComponent },

  {
    path: '',
    component: AppLayoutComponent,
    canActivate: [customTokenGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./directory/directory.routes'),
        canActivate: [maintenanceGuard],
      },
      {
        path: 'account',
        loadChildren: () => import('./account/account.routes'),
        canActivate: [authGuard, maintenanceGuard],
      },
      { path: 'verify', component: VerifyEmailComponent },
      { path: maintenancePath, component: MaintenanceComponent },
      { path: '404', component: NotFoundComponent },
      { path: '**', component: NotFoundComponent },
    ],
  },
];
