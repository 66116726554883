import { groupBy } from 'lodash';

import {
  ApiAccountDetails,
  ApiMembership,
  isDonationEditable,
  isFiscalYearEditable,
} from '@one-percent/shared';

import { parseFiscalYear } from '../data-access/fiscal-year/parse-fiscal-year';
import { NavItem } from '../shared/nav/nav-list/nav-list.component';
import { isFrance } from './account.util';
import { isCertificationOverdue } from './certification/is-certification-overdue';

interface Context {
  membership: ApiMembership;
  account: ApiAccountDetails | null;
}

interface AccountNavItem extends Omit<NavItem, 'badge'> {
  badge?: (context: Context) => boolean;
  show?: (context: Context) => boolean;
}

export const accountNavItems: AccountNavItem[] = [
  {
    label: 'Dashboard',
    icon: 'dashboard',
    link: ['/account/dashboard'],
  },
  {
    label: 'Profile',
    icon: 'badge',
    items: [
      { label: 'About', link: ['/account/profile/about'] },
      { label: 'Media', link: ['/account/profile/media'] },
      { label: 'Contact Info', link: ['/account/profile/contact'] },
    ],
  },
  {
    label: 'Ways to Give',
    icon: 'favorite',
    link: ['/account/ways-to-give'],
    show: ({ membership }) => membership.type === 'business',
  },
  {
    label: 'Supporters',
    icon: 'favorite',
    link: ['/account/supporters'],
    show: ({ membership }) => membership.type === 'nonprofit',
  },
  {
    label: 'Donations',
    icon: 'volunteer_activism',
    link: ['/account/donations'],
    badge: hasDonationRequiringAttention,
    show: ({ membership }) => membership.type === 'business',
  },
  {
    label: 'Certification',
    icon: 'card_membership',
    link: ['/account/certification'],
    badge: hasCertificationRequiringAttention,
    show: ({ membership }) => membership.type === 'business',
  },
  {
    label: 'Membership Dues',
    icon: 'today',
    link: ['/account/dues'],
    show: ({ membership }) => membership.type === 'business',
  },
  {
    label: 'Questionnaire',
    icon: 'quiz',
    link: ['/account/questions'],
    show: ({ membership, account }) =>
      membership.type === 'nonprofit' && account?.hideQuestionnaire === false,
  },
  {
    label: 'Users',
    icon: 'group',
    link: ['/account/users'],
    show: ({ membership }) => membership.currentUser.isAdmin,
  },
  {
    label: 'Agreement',
    icon: 'handshake',
    link: ['/account/agreement'],
    show: ({ membership }) => membership.type === 'nonprofit',
  },
  {
    label: 'Suggest Partner',
    icon: 'add_comment',
    link: ['/account/suggest'],
    show: ({ membership }) => membership.type === 'business',
  },
  {
    label: 'Contact Us',
    icon: 'mail',
    link: ['/account/contact'],
    show: ({ membership }) =>
      membership.type === 'business' && !isFrance(membership.country),
  },
];

function hasCertificationRequiringAttention({ account }: Context) {
  return !!account?.fiscalYears.some(
    (year) =>
      year.certificationStatus === 'issue' ||
      isCertificationOverdue(parseFiscalYear(year)),
  );
}

function hasDonationRequiringAttention({ account }: Context) {
  if (!account) {
    return false;
  }

  const yearsById = groupBy(account.fiscalYears, (year) => year.id);

  return account.donations.some(
    (donation) =>
      isDonationEditable(donation) &&
      yearsById[donation.yearId]?.[0] &&
      isFiscalYearEditable(yearsById[donation.yearId][0]) &&
      (!donation.receiptFilename || donation.status === 'unapproved'),
  );
}
