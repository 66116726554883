import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { first, map } from 'rxjs/operators';

import { AuthService } from './auth.service';

export const authGuard: CanActivateFn = (route, state) => {
  const auth = inject(AuthService);
  const router = inject(Router);

  return auth.authState$.pipe(
    first(),
    map((user) => {
      if (!user) {
        return router.createUrlTree(['/auth'], {
          queryParams: { returnTo: state.url },
        });
      } else if (!user.emailVerified) {
        return router.createUrlTree(['/verify'], {
          queryParams: { returnTo: state.url },
        });
      } else {
        return true;
      }
    }),
  );
};
