import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';

@Injectable()
export class AppErrorHandler implements ErrorHandler {
  private readonly sentryErrorHandler = Sentry.createErrorHandler();

  handleError(error: unknown): void {
    if (isChunkLoadingError(error)) {
      if (confirm('A new version is available. Please refresh.')) {
        window.location.reload();
      }
    }
    this.sentryErrorHandler.handleError(error);
  }
}

function isChunkLoadingError(error: unknown): boolean {
  return error instanceof Error && error.message.includes('ChunkLoadError');
}
