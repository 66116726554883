import { KeyValuePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatProgressBar } from '@angular/material/progress-bar';

import { ApiMembership } from '@one-percent/shared';

import { TrackFormDirective } from '../../analytics/track-form.directive';
import { TrackLinkDirective } from '../../analytics/track-link.directive';

export interface ResourceLink {
  label: string;
  icon: string;
  url: string;
  // if specified, the link will make a POST request with params
  submit?: Record<string, string>;
}

@Component({
  selector: 'app-resource-menu',
  templateUrl: './resource-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TrackFormDirective,
    MatMenuItem,
    MatIcon,
    TrackLinkDirective,
    MatProgressBar,
    KeyValuePipe,
  ],
})
export class ResourceMenuComponent {
  @ViewChild(MatMenu, { static: true }) matMenu!: MatMenu;

  @Input() resources?: ResourceLink[] | null = null;
  @Input() selectedMembership?: ApiMembership | null;
}
