@if (state$ | async; as state) {
  <app-splash-layout>
    @if (state.mode === 'forgot') {
      <app-forgot-password
        (afterSendEmail)="changeMode('login')"
        (returnToSignIn)="changeMode('login')"
      />
    }

    @if (state.mode === 'resetPassword') {
      <app-reset-password
        [email]="state.email"
        [oobCode]="state.oobCode"
        (afterSignIn)="returnToApp()"
        (returnToSignIn)="changeMode('login')"
      />
    }

    @if (state.mode === 'login') {
      <app-sign-in
        (afterSignIn)="returnToApp()"
        (forgotPassword)="changeMode('forgot')"
      />
    }

    @if (state.mode === 'invite') {
      <app-sign-up
        [email]="state.email"
        (afterSignUp)="returnToApp()"
        (signIn)="changeMode('login')"
      />
    }

    @if (state.mode === 'error' || state.mode === 'loading') {
      <div class="auth-card">
        <div class="auth-card__back">
          <a mat-button routerLink="/auth">
            <mat-icon>navigate_before</mat-icon> Sign In
          </a>
        </div>
        <app-message
          size="large"
          [type]="state.mode === 'error' ? 'error' : 'loading'"
          [title]="state.mode === 'error' ? state.error.title : 'Loading…'"
          [message]="state.mode === 'error' ? state.error.message : undefined"
          [border]="false"
        />
        @if (state.mode === 'error' && state.accountExists) {
          <div class="auth-card__footer">
            <button
              type="button"
              class="mt-1 mb-3"
              mat-stroked-button
              color="primary"
              (click)="changeMode('login')"
            >
              Sign In
            </button>
          </div>
        }
      </div>
    }
  </app-splash-layout>
}
