import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { map } from 'rxjs/operators';

import { MaintenanceService } from './maintenance.service';

export const maintenanceGuard: CanActivateFn = (route, state) => {
  const service = inject(MaintenanceService);
  return service.status$.pipe(
    map((status) => (status ? service.createMaintenanceUrl(state.url) : true)),
  );
};
