import seedrandom from 'seedrandom';

export const secondaryColors = [
  'green',
  'blue',
  'yellow',
  'purple',
  'cyan',
] as const;

export type SecondaryColor = (typeof secondaryColors)[number];

export function getRandomSecondaryColor(seed: string) {
  const rng = seedrandom(seed);
  const i = Math.floor(rng() * Math.floor(secondaryColors.length));
  return secondaryColors[i];
}
