import { AccountType, ApiSocialAttributes } from '@one-percent/shared';

import { sfAccountImpactAreaOptions as impactAreaOptions } from '../generated/salesforce';

interface Option<T extends string = string> {
  value: T;
  label: string;
  icon?: string;
}

export const passwordMinLength = 6;
export const passwordHint = `At least ${passwordMinLength} characters`;

export const accountTypeOptions: Option<AccountType>[] = [
  {
    label: 'Env. Partners',
    value: 'nonprofit',
  },
  {
    label: 'Businesses',
    value: 'business',
  },
];

export const groupedImpactAreaOptions: Array<{
  label: string;
  options: Option[];
}> = [
  {
    label: 'Rights to Nature',
    options: impactAreaOptions.slice(0, 4),
  },
  {
    label: 'Conservation & Restoration',
    options: impactAreaOptions.slice(4, 8),
  },
  {
    label: 'Resilient Communities',
    options: impactAreaOptions.slice(8, 12),
  },
  {
    label: 'Just Economies',
    options: impactAreaOptions.slice(12),
  },
];

export const socialOptions: Option<keyof ApiSocialAttributes>[] = [
  {
    label: 'Facebook',
    value: 'facebook',
  },
  {
    label: 'Instagram',
    value: 'instagram',
  },
  {
    label: 'X',
    value: 'twitter',
  },
  {
    label: 'LinkedIn',
    value: 'linkedin',
  },
  {
    label: 'YouTube',
    value: 'youtube',
  },
];
