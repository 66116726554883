import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';

import { stateIconAnimations } from './state-icon.animations';

export type State = 'loading' | 'success' | 'error';

@Component({
  selector: 'app-state-icon',
  templateUrl: './state-icon.component.html',
  styleUrl: './state-icon.component.scss',
  animations: stateIconAnimations,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass],
})
export class StateIconComponent {
  @HostBinding('style.width')
  @HostBinding('style.height')
  get sizeInPx(): string {
    return `${this.size}px`;
  }

  @Input() theme: 'dark' | 'light' | 'primary-dark' = 'light';

  @Input() state: State = 'loading';

  @Input() size = 72;
  @Input() strokeWidth = 4;
}
