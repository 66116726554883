import { isArray } from 'lodash';
import { combineLatest, merge, Observable, of, throwError } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';

import { ApiFile, isNotError, isNotNil } from '@one-percent/shared';

import { isExistingFile } from '../../shared/file/file-manager/existing-file';
import { isNewFile, NewFile } from '../../shared/file/file-manager/new-file';

export function resolveFileValue(value: unknown): Observable<ApiFile[]> {
  if (!isArray(value)) {
    throw new Error('File values should be an array');
  }

  if (value.length === 0) {
    return of([]);
  }

  return combineLatest(
    value.map((v) => {
      if (isNewFile(v)) {
        return resolveNewFileValue(v);
      } else if (isExistingFile(v)) {
        return of({ id: v.id, name: v.name });
      } else {
        return throwError(
          () => new Error(`Invalid file value: ${JSON.stringify(v, null, 2)}`),
        );
      }
    }),
  ).pipe(
    first(),
    map((values) => values.filter(isNotError)),
  );
}

function resolveNewFileValue(value: NewFile) {
  return merge(
    value.uploadComplete$.pipe(map(({ key }) => ({ s3key: key }))),
    value.uploadError$.pipe(filter(isNotNil)),
  ).pipe(first());
}
