import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButton } from '@angular/material/button';
import {
  MatFormField,
  MatLabel,
  MatSuffix,
} from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { BehaviorSubject } from 'rxjs';

import { AppTitleService } from '../../app-title.service';
import { AnalyticsService } from '../../shared/analytics/analytics.service';
import { describeAuthError } from '../../utility/firebase-errors';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatSuffix,
    MatButton,
    AsyncPipe,
  ],
})
export class SignInComponent {
  @Output() readonly afterSignIn = new EventEmitter<void>();
  @Output() readonly forgotPassword = new EventEmitter<void>();

  readonly errorMessage$ = new BehaviorSubject<string | undefined>(undefined);
  readonly submitting$ = new BehaviorSubject<boolean>(false);

  readonly form = this.fb.nonNullable.group({
    email: ['', [Validators.email, Validators.required]],
    password: ['', Validators.required],
  });

  constructor(
    private analytics: AnalyticsService,
    private auth: AuthService,
    private appTitle: AppTitleService,
    private fb: FormBuilder,
  ) {}

  async signIn(): Promise<void> {
    if (this.form.invalid) {
      return;
    }

    this.errorMessage$.next(undefined);
    this.submitting$.next(false);

    const { email, password } = this.form.getRawValue();

    try {
      const { user } = await this.auth.signIn(email, password);
      if (user) {
        this.analytics.identifyUser(user.uid);
        this.analytics.track('Signed in');
      }
      this.afterSignIn.emit();
    } catch (error) {
      this.errorMessage$.next(describeAuthError(error));
    }

    this.submitting$.next(false);
  }
}
