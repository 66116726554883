@for (resource of resources; track resource.url) {
  @if (resource.submit) {
    <form
      [action]="resource.url"
      method="post"
      target="_blank"
      appTrackForm="Clicked resource link"
      [appTrackFormProperties]="{
        'Resource Name': resource.label,
        'Resource URI': resource.url,
        'Account ID': selectedMembership?.id,
        'Account Type': selectedMembership?.type
      }"
    >
      @for (input of resource.submit | keyvalue; track input.key) {
        <input type="hidden" [name]="input.key" [value]="input.value" />
      }
      <button mat-menu-item type="submit">
        <mat-icon>{{ resource.icon }}</mat-icon>
        {{ resource.label }}
      </button>
    </form>
  } @else {
    <a
      mat-menu-item
      [href]="resource.url"
      target="_blank"
      appTrackLink="Clicked resource link"
      [appTrackLinkProperties]="{
        'Resource Name': resource.label,
        'Resource URI': resource.url,
        'Account ID': selectedMembership?.id,
        'Account Type': selectedMembership?.type
      }"
    >
      <mat-icon>{{ resource.icon }}</mat-icon>
      {{ resource.label }}
    </a>
  }
}

@if (!resources) {
  <div mat-menu-item disabled class="flex items-center">
    <mat-progress-bar mode="indeterminate" />
  </div>
}
@if (resources?.length === 0) {
  <span mat-menu-item disabled> No resources </span>
}
