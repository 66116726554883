import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { ApiInviteError } from '@one-percent/shared';

import { UserApiService } from '../data-access/user/user-api.service';

// from https://github.com/angular/angular/blob/bd0d19141b241173439cb7ed8b8578d4c0ee60a2/packages/forms/src/validators.ts#L98
const EMAIL_REGEXP =
  /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export class InviteError extends Error {
  constructor(readonly code: ApiInviteError) {
    super(code);
  }
}

@Injectable({ providedIn: 'root' })
export class InviteService {
  constructor(private userApi: UserApiService) {}

  async checkInviteCode(code: string) {
    const email = parseEmail(code);

    if (!email || !isValidEmail(email)) {
      throw new InviteError('invalid');
    }

    const { error } = await firstValueFrom(this.userApi.getInvite(email));
    if (error) {
      throw new InviteError(error);
    }
    return { email };
  }
}

/**
 * Parses the invite email from the URL param.
 * Generated invite links have the email param base-64 encoded,
 * but hand-written links may have the email in plaintext.
 */
function parseEmail(param: string): string | null {
  if (param.includes('@')) {
    // email is not base-64 encoded
    return param;
  }

  try {
    return atob(param);
  } catch (error) {
    return null;
  }
}

function isValidEmail(email: string): boolean {
  return EMAIL_REGEXP.test(email);
}
