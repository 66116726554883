/**
 * Formats a date in UTC time in the ISO8601 format.
 */
export function formatUTCISO(
  date: Date,
  representation: 'date' | 'time' | 'complete' = 'complete',
): string {
  const iso = date.toISOString();
  switch (representation) {
    case 'date':
      return iso.slice(0, 10);
    case 'time':
      return iso.slice(11);
    default:
      return iso;
  }
}
