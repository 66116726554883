import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class AppTitleService {
  constructor(private title: Title) {}

  setTitle(title: string): void {
    this.title.setTitle(`${title} · 1% for the Planet`);
  }

  getTitle(): string {
    return this.title.getTitle();
  }
}
