import { HttpErrorResponse } from '@angular/common/http';

import { describeAuthError, isFirebaseError } from './firebase-errors';

export interface DescribedError {
  title: string;
  message: string;
}

export function describeError(error: unknown): DescribedError {
  const defaults: Pick<DescribedError, 'title'> = {
    title: 'Something went wrong!',
  };

  if (isFirebaseError(error)) {
    return {
      ...defaults,
      message: describeAuthError(error),
    };
  }

  if (error instanceof HttpErrorResponse) {
    switch (error.status) {
      case 0:
        return { ...defaults, message: 'Couldn’t connect.' };
      default:
        return {
          ...defaults,
          message: responseHasMessage(error.error)
            ? error.error.message
            : `Unexpected error (HTTP ${error.status}).`,
        };
    }
  } else if (error instanceof Error) {
    return { ...defaults, message: error.message };
  } else if (typeof error === 'string') {
    return { ...defaults, message: error };
  } else {
    return { ...defaults, message: 'Unexpected error' };
  }
}

export function responseHasMessage(
  error: unknown,
): error is { message: string } {
  return typeof error === 'object' && error !== null && 'message' in error;
}
