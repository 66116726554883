import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { get } from 'lodash';
import { tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { responseHasMessage } from '../utility/describe-error';
import { MaintenanceService } from './maintenance.service';

const defaultMessage = 'We’re tuning up a few things and will be back soon.';

export const maintenanceInterceptor: HttpInterceptorFn = (req, next) => {
  const maintenanceService = inject(MaintenanceService);
  const router = inject(Router);

  if (req.url.startsWith(environment.apiUrl)) {
    return next(req).pipe(
      tap({
        error: (error) => {
          if (isMaintenanceError(error)) {
            maintenanceService.enable({
              message: responseHasMessage(error.error)
                ? error.error.message
                : defaultMessage,
            });
            if (!maintenanceService.isMaintenanceUrl(router.url)) {
              void router.navigateByUrl(
                maintenanceService.createMaintenanceUrl(router.url),
              );
            }
          }
        },
        complete: () => {
          maintenanceService.disable();
        },
      }),
    );
  } else {
    return next(req);
  }
};

function isMaintenanceError(error: unknown): error is HttpErrorResponse {
  return (
    error instanceof HttpErrorResponse &&
    error.status === 503 &&
    get(error, 'error.maintenance') === true
  );
}
