<form class="auth-card" [formGroup]="form" (submit)="signIn()" novalidate>
  <div class="auth-card__back">
    <a mat-button (click)="returnToSignIn.emit()">
      <mat-icon>navigate_before</mat-icon> Sign In
    </a>
  </div>

  <h1 class="mat-headline-4">Forgot Password?</h1>

  <div class="auth-card__content">
    <div class="mat-body">
      Enter your email address and we’ll send you instructions to reset your
      password.
    </div>

    <mat-form-field hideRequiredMarker>
      <mat-label>Email</mat-label>
      <input
        matInput
        type="email"
        formControlName="email"
        autocomplete="username"
      />
    </mat-form-field>

    <button
      type="submit"
      mat-raised-button
      color="primary"
      [disabled]="submitting$ | async"
    >
      {{ (submitting$ | async) ? 'Submitting…' : 'Send Reset Instructions' }}
    </button>
  </div>

  <div class="auth-card__footer text-warn">
    {{ errorMessage$ | async }}
  </div>
</form>
