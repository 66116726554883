import { Injectable } from '@angular/core';
import {
  ActionCodeSettings,
  Auth,
  checkActionCode,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  onAuthStateChanged,
  reauthenticateWithCredential,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  updatePassword,
  User,
  UserCredential,
} from '@angular/fire/auth';
import { applyActionCode } from '@angular/fire/auth';
import { from, of, ReplaySubject, shareReplay } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthService {
  get currentUser() {
    return this.auth.currentUser;
  }

  readonly #authStateSubject = new ReplaySubject<User | null>(1);

  readonly authState$ = this.#authStateSubject.asObservable();

  readonly isImpersonating$ = this.authState$.pipe(
    switchMap((user) =>
      user
        ? from(user.getIdTokenResult()).pipe(
            map((result) => result.signInProvider === 'custom'),
          )
        : of(false),
    ),
    shareReplay(1),
  );

  constructor(private auth: Auth) {
    onAuthStateChanged(this.auth, (user) => {
      this.#authStateSubject.next(user);
    });
  }

  createUser(email: string, password: string): Promise<UserCredential> {
    return createUserWithEmailAndPassword(this.auth, email, password);
  }

  sendEmailVerification(
    user: User,
    settings?: ActionCodeSettings,
  ): Promise<void> {
    return sendEmailVerification(user, settings);
  }

  sendPasswordResetEmail(
    email: string,
    actionCodeSettings?: ActionCodeSettings,
  ): Promise<void> {
    return sendPasswordResetEmail(this.auth, email, actionCodeSettings);
  }

  confirmPasswordReset(oobCode: string, newPassword: string) {
    return confirmPasswordReset(this.auth, oobCode, newPassword);
  }

  signIn(email: string, password: string): Promise<UserCredential> {
    return signInWithEmailAndPassword(this.auth, email, password);
  }

  async signInWithCustomToken(token: string) {
    return signInWithCustomToken(this.auth, token);
  }

  async applyActionCode(oobCode: string) {
    await applyActionCode(this.auth, oobCode);
  }

  async checkActionCode(oobCode: string) {
    const { data } = await checkActionCode(this.auth, oobCode);
    return data;
  }

  signOut(): Promise<void> {
    return this.auth.signOut();
  }

  async updatePassword(
    user: User,
    currentPassword: string,
    newPassword: string,
  ) {
    if (!user.email) {
      throw new Error('User email is required to update password');
    }

    // Firebase only requires recent authentication to update password,
    // but we always re-authenticate here for consistency
    await reauthenticateWithCredential(
      user,
      EmailAuthProvider.credential(user.email, currentPassword),
    );

    await updatePassword(user, newPassword);
  }
}
