import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatAnchor, MatButton } from '@angular/material/button';
import { MatFormField, MatHint, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';

import { passwordHint, passwordMinLength } from '../../app.constants';
import { ProgressSnackbar } from '../../shared/progress/progress.snackbar';
import { describeAuthError } from '../../utility/firebase-errors';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatAnchor,
    MatIcon,
    MatFormField,
    MatLabel,
    MatInput,
    MatHint,
    MatButton,
    AsyncPipe,
  ],
})
export class ResetPasswordComponent {
  @Input() oobCode!: string;
  @Input() email?: string;

  @Output() readonly afterSignIn = new EventEmitter<void>();
  @Output() readonly returnToSignIn = new EventEmitter<void>();

  readonly passwordHint = passwordHint;
  readonly errorMessage$ = new BehaviorSubject<string | undefined>(undefined);
  readonly submitting$ = new BehaviorSubject<boolean>(false);

  readonly form = this.fb.nonNullable.group({
    newPassword: [
      '',
      [Validators.required, Validators.minLength(passwordMinLength)],
    ],
  });

  constructor(
    private auth: AuthService,
    private fb: FormBuilder,
    private snackbar: MatSnackBar,
  ) {}

  async submit() {
    if (this.form.invalid) {
      return;
    }

    this.errorMessage$.next(undefined);
    this.submitting$.next(true);

    const { newPassword } = this.form.getRawValue();

    try {
      await this.setPasswordAndSignIn(newPassword);
      ProgressSnackbar.showSuccess(this.snackbar, 'Password reset');
      this.afterSignIn.emit();
    } catch (error) {
      this.errorMessage$.next(describeAuthError(error));
    }

    this.submitting$.next(false);
  }

  private async setPasswordAndSignIn(newPassword: string) {
    await this.auth.confirmPasswordReset(this.oobCode, newPassword);
    if (this.email) {
      await this.auth.signIn(this.email, newPassword);
    }
  }
}
