<app-state-icon
  class="shrink-0 me-3"
  theme="dark"
  [size]="36"
  [state]="state"
/>

@switch (state) {
  @case ('loading') {
    <span>{{ loadingMessage }}…</span>
  }
  @case ('success') {
    <span>{{ completeMessage }}</span>
  }
  @case ('error') {
    <span>{{ errorMessage }}</span>
  }
}

@if (state === 'success' && completeAction) {
  <button
    matSnackBarAction
    class="ms-auto"
    type="button"
    mat-button
    [routerLink]="completeAction.link"
    (click)="ref.dismissWithAction()"
  >
    {{ completeAction.label }}
  </button>
}
