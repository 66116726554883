import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

import { AnalyticsService } from './analytics.service';

@Directive({
  selector: '[appTrackLink]',
  standalone: true,
})
export class TrackLinkDirective implements AfterViewInit {
  @Input('appTrackLink') name!: string;
  @Input('appTrackLinkProperties') properties?: Record<string, unknown>;

  constructor(
    private analytics: AnalyticsService,
    private elementRef: ElementRef<HTMLElement>,
  ) {}

  ngAfterViewInit() {
    this.analytics.trackLinks(
      this.elementRef.nativeElement,
      this.name,
      this.properties,
    );
  }
}
