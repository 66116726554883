<form class="auth-card" [formGroup]="form" (submit)="signIn()" novalidate>
  <h1 class="mat-headline-4">Sign In</h1>
  <div class="auth-card__content">
    <mat-form-field hideRequiredMarker>
      <mat-label>Email</mat-label>
      <input
        matInput
        type="email"
        formControlName="email"
        autocomplete="username"
      />
    </mat-form-field>
    <mat-form-field hideRequiredMarker>
      <mat-label>Password</mat-label>
      <input
        matInput
        type="password"
        formControlName="password"
        autocomplete="current-password"
      />
      <button
        type="button"
        mat-button
        matIconSuffix
        class="me-2 text-primary opacity-80 hover:opacity-100"
        (click)="forgotPassword.emit()"
      >
        Forgot?
      </button>
    </mat-form-field>
    <button
      type="submit"
      mat-raised-button
      color="primary"
      [disabled]="submitting$ | async"
    >
      {{ (submitting$ | async) ? 'Signing in…' : 'Sign In' }}
    </button>
  </div>

  <div class="auth-card__footer text-warn">
    {{ errorMessage$ | async }}
  </div>
</form>
