import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  ApiCreateUserArgs,
  ApiInvite,
  ApiUpdateUserArgs,
  ApiUser,
} from '@one-percent/shared';

import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class UserApiService {
  constructor(private http: HttpClient) {}

  getInvite(email: string): Observable<ApiInvite> {
    return this.http.get<ApiInvite>(`${environment.apiUrl}/invites/${email}`);
  }

  create({
    accountId,
    ...args
  }: ApiCreateUserArgs & {
    accountId: string;
  }): Observable<ApiUser> {
    return this.http.post<ApiUser>(
      `${environment.apiUrl}/accounts/${accountId}/users`,
      args,
    );
  }

  update({
    accountId,
    id,
    ...args
  }: ApiUpdateUserArgs & {
    accountId: string;
    id: string;
  }): Observable<void> {
    return this.http.put<void>(
      `${environment.apiUrl}/accounts/${accountId}/users/${id}`,
      args,
    );
  }

  delete({
    accountId,
    id,
  }: {
    accountId: string;
    id: string;
  }): Observable<void> {
    return this.http.delete<void>(
      `${environment.apiUrl}/accounts/${accountId}/users/${id}`,
    );
  }
}
