<div
  class="layout-container flex flex-col items-center gap-8 md:flex-row md:gap-32"
>
  <a class="flex-1" routerLink="/">
    <img
      class="w-24 drop-shadow sm:w-44 md:w-full"
      src="assets/logo-light-vertical.svg"
      alt="1% for the Planet"
    />
  </a>

  <div class="flex-1 max-w-full">
    <ng-content />
  </div>
</div>
