<div class="nav">
  @if (authState && resources?.length !== 0) {
    <app-nav-list
      cdkFocusInitial
      [navItems]="navItems"
      [navigateToFirstSubItem]="false"
      [placeholderRows]="4"
      [showIcons]="true"
      (clickLink)="sidenav?.close()"
    />
  }

  <mat-nav-list>
    <mat-divider />
    <a
      mat-list-item
      [routerLink]="['/']"
      [class.active]="nav.isDirectoryRouteActive$ | async"
      (click)="sidenav?.close()"
    >
      <mat-icon matListItemIcon>travel_explore</mat-icon>
      <span matListItemTitle>Directory</span>
    </a>

    @if (authState) {
      <a
        mat-list-item
        routerLink="/favorites"
        routerLinkActive="active"
        (click)="sidenav?.close()"
      >
        <mat-icon matListItemIcon>favorite</mat-icon>
        <span matListItemTitle>Favorites</span>
      </a>
      <mat-list-item
        [matMenuTriggerFor]="resourceMenu"
        [disabled]="resources?.length === 0"
      >
        <mat-icon matListItemIcon>help</mat-icon>
        <span matListItemTitle>Resources</span>
      </mat-list-item>
      <mat-menu #resourceMenu="matMenu">
        <app-resource-menu
          [resources]="resources"
          [selectedMembership]="selectedMembership"
        />
      </mat-menu>
    }

    <mat-divider />

    <a
      mat-list-item
      href="https://onepercentfortheplanet.org/privacy-policy"
      target="_blank"
    >
      <mat-icon matListItemIcon>policy</mat-icon>
      <span matListItemTitle>Privacy policy</span>
    </a>
    <a
      mat-list-item
      href="https://onepercentfortheplanet.org/terms-of-use"
      target="_blank"
    >
      <mat-icon matListItemIcon>check_circle</mat-icon>
      <span matListItemTitle>Terms of use</span>
    </a>
  </mat-nav-list>

  <mat-action-list>
    <button
      mat-list-item
      (click)="cookieConsent.openPreferences(); sidenav?.close()"
    >
      <mat-icon matListItemIcon>cookie</mat-icon>
      <span matListItemTitle>Cookie preferences</span>
    </button>
  </mat-action-list>
</div>
