export const donationDenialReasons: Record<string, string> = {
  'Cannot open document':
    'We can’t open the attached document. Please upload a new document in PDF or JPEG format (not password protected or editable).',
  'Uncashed checks are not accepted':
    'We can’t approve an uncleared check. Please upload a payment receipt, proof of cashed check, or an acknowledgement letter/email from the in-network partner stating the date and amount of the donation.',
  'Editable documents are not accepted':
    'We can’t approve editable documents. Please upload a new document in an un-editable format (PDF/JPEG).',
  'Documentation does not match':
    'The document you provided for this donation does not match. Please upload a document that matches.',
  'Environmental Partner’s name must be on document':
    'The document does not include the Environmental Partner’s name. Please upload a new document.',
  'Environmental Partner’s name does not match':
    'The selected environmental partner’s name does not match the document. Please update the record and/or document so that the two match.',
  'The date/date range of donation must be on document':
    'The document does not include the date of donation. Please upload a new document.',
  'The donation amount must be on document':
    'The document does not include the donation amount. Please upload a new document.',
  'The business name must be on document':
    'The document does not include your business name. Please upload a new document.',
  'Proof of payment must be on document':
    'The document does not include proof of payment. Please upload a new document.',
};
