import { differenceInCalendarDays } from 'date-fns';

import { FiscalYear } from '../../data-access/fiscal-year/fiscal-year';

export function isCertificationOverdue(
  fiscalYear: Pick<FiscalYear, 'certificationStatus' | 'certificationDueDate'>,
): boolean {
  const now = new Date();

  return (
    fiscalYear.certificationStatus === 'draft' &&
    differenceInCalendarDays(fiscalYear.certificationDueDate, now) <= 0
  );
}
