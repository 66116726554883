import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { MonoTypeOperatorFunction } from 'rxjs';

import { watch } from '../../utility/custom-operators';
import { describeError } from '../../utility/describe-error';
import { ProgressSnackbar, ProgressSnackbarData } from './progress.snackbar';

export interface WatchProgressOptions {
  message?: string;
  completeMessage?: string;
  completeAction?: {
    label: string;
    link: unknown[];
  };
  showCompleteMessage?: boolean;
}

@Injectable({ providedIn: 'root' })
export class ProgressService {
  private snackBarRef?: MatSnackBarRef<ProgressSnackbar>;

  constructor(private snackbar: MatSnackBar) {}

  show(options?: ProgressSnackbarData): MatSnackBarRef<ProgressSnackbar> {
    return (this.snackBarRef = ProgressSnackbar.open(this.snackbar, options));
  }

  hide(): void {
    this.snackBarRef?.dismiss();
  }

  /**
   * Use this with the RxJS `pipe()` method to show a progress indicator,
   * which can be either a dialog (default, blocking) or a snackbar (non-blocking).
   *
   * @example
   * apiService.users$
   *   .pipe(progressService.watch())
   *   .subscribe(() => {...});
   */
  watch<T>(options?: WatchProgressOptions): MonoTypeOperatorFunction<T> {
    return watch(
      () => this.show(options),
      (snackBarRef) => {
        if (options?.showCompleteMessage !== false) {
          snackBarRef.instance.dismissWithSuccess();
        } else {
          snackBarRef.dismiss();
        }
      },
      (snackBarRef, error) => {
        snackBarRef.instance.dismissWithError(describeError(error).title);
      },
    );
  }
}
