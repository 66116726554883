import { AccountType } from '@one-percent/shared';

import { ResourceLink } from '../shared/nav/resource-menu/resource-menu.component';

export interface AccountResource extends ResourceLink {
  show?: (accountTypes: AccountType[]) => boolean;
}

export const accountResources: AccountResource[] = [
  {
    label: 'Network Offers',
    icon: 'local_offer',
    url: 'https://www.onepercentfortheplanet.org/.wf_auth',
    submit: {
      pass: '1%offers2021!',
      path: '/offers',
      page: '/offers',
    },
    show: (accountTypes) => accountTypes.includes('business'),
  },
  {
    label: 'Social Hours',
    icon: 'event',
    url: 'https://membersupport.onepercentfortheplanet.org/get-involved/virtual-social-hours',
    show: (accountTypes) => accountTypes.includes('business'),
  },
  {
    label: 'Slack for Members',
    icon: 'forum',
    url: 'https://onepercentfortheplanet.org/slack-for-business-members',
    show: (accountTypes) => accountTypes.includes('business'),
  },
  {
    label: 'Member Resource Center',
    icon: 'build_circle',
    url: 'https://membersupport.onepercentfortheplanet.org',
    show: (accountTypes) => accountTypes.includes('business'),
  },
  {
    label: 'Member Office Hours',
    icon: 'event',
    url: 'https://www.eventbrite.com/cc/membership-office-hours-2914139?aff=odclrlmcfto',
    show: (accountTypes) => accountTypes.includes('business'),
  },
  {
    label: 'Certification Office Hours',
    icon: 'event',
    url: 'https://www.eventbrite.com/cc/certification-office-hours-27519',
    show: (accountTypes) => accountTypes.includes('business'),
  },
  {
    label: 'Partner Resource Center',
    icon: 'build_circle',
    url: 'https://partnersupport.onepercentfortheplanet.org',
    show: (accountTypes) => accountTypes.includes('nonprofit'),
  },
  {
    label: 'Partner FAQ',
    icon: 'help',
    url: 'https://partnersupport.onepercentfortheplanet.org/faq/environmental-partner-faqs',
    show: (accountTypes) => accountTypes.includes('nonprofit'),
  },
];
