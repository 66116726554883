<svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 52 52"
  [@state]="{ value: state, params: { size: size } }"
  [ngClass]="[theme, state]"
  [attr.stroke-width]="strokeWidth"
>
  <circle
    [@strokeCircle]="state"
    class="circle"
    cx="26"
    cy="26"
    r="25"
    fill="none"
  />
  <path
    [@strokeCheckmark]="state"
    class="symbol"
    fill="none"
    d="M14.1 27.2l7.1 7.2 16.7-16.8"
  />
  <path
    [@strokeX]="state"
    class="symbol"
    fill="none"
    d="M16 16 36 36 M36 16 16 36"
  />
</svg>
