import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CookieConsentService {
  /**
   * Triggers the cookie preferences drawer.
   * https://docs.osano.com/hiding-the-cookie-widget
   */
  openPreferences() {
    Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
  }
}
