import { Injectable } from '@angular/core';
import { Event, NavigationStart, Router } from '@angular/router';
import { filter, map, startWith } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class NavService {
  readonly currentUrl$ = this.router.events.pipe(
    filter(isNavigationStart),
    map((e) => e.url),
    startWith(this.router.url),
  );

  // directory URLs may match `/`, `/?query`, or `/profile/:accountId`
  readonly isDirectoryRouteActive$ = this.currentUrl$.pipe(
    map(
      (url) =>
        url === '/' || url.startsWith('/?') || url.startsWith('/profile/'),
    ),
  );

  get bypassWarnBeforeLeaving() {
    return this.#bypassWarnBeforeLeaving;
  }
  #bypassWarnBeforeLeaving = false;

  constructor(private router: Router) {}

  openUrlWithoutWarning(url: string) {
    this.#bypassWarnBeforeLeaving = true;
    location.href = url;
    this.#bypassWarnBeforeLeaving = false;
  }
}

function isNavigationStart(e: Event): e is NavigationStart {
  return e instanceof NavigationStart;
}
