export function describeAuthError(error: unknown): string {
  if (isFirebaseError(error)) {
    switch (error.code) {
      case 'auth/email-already-in-use':
        return 'Account already exists.';
      case 'auth/expired-action-code':
        return 'Code expired.';
      case 'auth/invalid-action-code':
        return 'Invalid code.';
      case 'auth/invalid-credential':
        return 'Incorrect email or password. If you were invited, please use the invite link shared with you.';
      case 'auth/invalid-email':
        return 'Invalid email.';
      case 'auth/too-many-requests':
        return 'Too many attempts. Please try again later.';
      case 'auth/user-disabled':
        return 'Couldn’t find your account.';
      case 'auth/user-not-found':
        return 'Couldn’t find your account. If you were invited, please use the invite link shared with you.';
      case 'auth/wrong-password':
        return 'Incorrect password.';
    }
  }
  if (error instanceof Error) {
    return error.message;
  }
  return 'Unexpected error.';
}

export function isFirebaseError(obj: unknown): obj is { code: string } {
  return typeof obj === 'object' && obj !== null && 'code' in obj;
}
