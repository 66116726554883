import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatAnchor, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params, Router, RouterLink } from '@angular/router';
import { isString } from 'lodash';
import { combineLatest, Observable, shareReplay, switchMap } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';

import { AppTitleService } from '../app-title.service';
import { MessageComponent } from '../shared/message/message.component';
import { ProgressSnackbar } from '../shared/progress/progress.snackbar';
import { SplashLayoutComponent } from '../shared/splash-layout/splash-layout.component';
import { DescribedError, describeError } from '../utility/describe-error';
import { AuthService } from './auth.service';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { InviteError, InviteService } from './invite.service';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';

type State =
  | { mode: 'error'; error: DescribedError; accountExists?: boolean }
  | { mode: 'loading' | 'login' | 'forgot' }
  | { mode: 'invite'; email: string }
  | {
      mode: 'resetPassword';
      oobCode: string;
      email?: string;
    };

const pageTitles: Record<State['mode'], string> = {
  error: 'Error',
  forgot: 'Forgot Password',
  login: 'Sign In',
  invite: 'You’re invited!',
  loading: 'Loading...',
  resetPassword: 'Reset Password',
};

/**
 * Handler for auth actions such as login & invites,
 * as well as Firebase Auth email actions:
 * https://firebase.google.com/docs/auth/custom-email-handler
 */
@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SplashLayoutComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    SignInComponent,
    SignUpComponent,
    MatAnchor,
    RouterLink,
    MatIcon,
    MessageComponent,
    MatButton,
    AsyncPipe,
  ],
})
export class AuthComponent {
  readonly state$: Observable<State> = combineLatest([
    this.route.params,
    this.route.queryParams,
  ]).pipe(
    // params may be passed in the route (e.g. `/auth/invite/user@example.com`),
    // or as query params (e.g. `/auth?mode=resetPassword`)
    map(([params, queryParams]) => ({
      ...queryParams,
      ...params,
    })),
    switchMap((params) => this.getState(params)),
    startWith({ mode: 'loading' as const }),
    tap(({ mode }) => {
      this.appTitle.setTitle(pageTitles[mode]);
    }),
    shareReplay(1),
  );

  constructor(
    private appTitle: AppTitleService,
    private auth: AuthService,
    private inviteService: InviteService,
    private route: ActivatedRoute,
    private router: Router,
    private snackbar: MatSnackBar,
  ) {}

  returnToApp() {
    const uri: unknown = this.route.snapshot.queryParams['returnTo'];
    void this.router.navigateByUrl(
      typeof uri === 'string' ? decodeURI(uri) : '/account',
    );
  }

  changeMode(mode: State['mode']) {
    return this.router.navigate(['/auth'], {
      queryParams: { mode, apiKey: undefined, oobCode: undefined },
      queryParamsHandling: 'merge',
    });
  }

  private async getState(params: Params): Promise<State> {
    if (!isString(params['mode'])) {
      return { mode: 'login' };
    }

    try {
      if (params['mode'] === 'forgot' || params['mode'] === 'login') {
        // Simple actions without additional properties
        return { mode: params['mode'] };
      } else if (params['mode'] === 'invite' && isString(params['email'])) {
        // Invite action
        const { email } = await this.inviteService.checkInviteCode(
          params['email'],
        );
        return { mode: params['mode'], email };
      } else if (isString(params['oobCode'])) {
        // Firebase Auth email actions
        switch (params['mode']) {
          case 'resetPassword': {
            const { email } = await this.auth.checkActionCode(
              params['oobCode'],
            );
            return {
              mode: params['mode'],
              oobCode: params['oobCode'],
              email: email ?? undefined,
            };
          }
          case 'verifyEmail': {
            await this.auth.applyActionCode(params['oobCode']);
            await this.auth.currentUser?.reload();
            ProgressSnackbar.showSuccess(this.snackbar, 'Email verified');
            this.returnToApp();
            return { mode: 'loading' };
          }
        }
      }
    } catch (error) {
      if (error instanceof InviteError) {
        return {
          mode: 'error',
          error: describeError(
            error.code === 'account-exists'
              ? 'Account already exists.'
              : 'Couldn’t find your account.',
          ),
          accountExists: error.code === 'account-exists',
        };
      } else {
        return { mode: 'error', error: describeError(error) };
      }
    }

    return { mode: 'error', error: describeError('Invalid parameters.') };
  }
}
