import { ArrayValues } from 'type-fest';

import { ApiAccountProfileStub } from './account';
import { ApiFile } from './file';

export interface ApiDonation {
  id: string;
  yearId: string;
  createdAt: string;
  recipient: ApiDonationRecipient;
  program?: string;
  amount: number;
  amountUsd?: number;
  date?: string;
  type: DonationType;
  mediaOutlet?: string;
  volunteerHours?: number;
  volunteerCount?: number;
  volunteerPay?: number;
  volunteerPayUsd?: number;
  receiptFilename?: string;
  public: boolean;
  status: DonationStatus;
  reviewedAt?: string;
  denialReasons?: string[];
  userConverted?: {
    amount: number;
    currency: string;
    date: string;
    rate: number;
  };
  userNotes?: string;
  investmentCategory?: string;
  investmentProvider?: string;
}

export interface ApiDonationRecipient extends ApiAccountProfileStub {
  id: string;
}

export interface ApiCreateDonationArgs {
  recipientId: string;
  program?: string | null;
  amount: number;
  fromUsd?: boolean;
  date: string;
  type: DonationType;
  mediaOutlet?: string;
  volunteerHours?: number;
  volunteerCount?: number;
  volunteerPay?: number;
  public?: boolean;
  receipt?: ApiFile | null;
  userConverted?: {
    amount: number;
    currency: string;
    date: string;
    rate: number;
  } | null;
  userNotes?: string;
  investmentCategory?: string;
  investmentProvider?: string;
}

export type ApiUpdateDonationArgs = Partial<ApiCreateDonationArgs>;

export const donationStatuses = ['draft', 'approved', 'unapproved'] as const;
export type DonationStatus = ArrayValues<typeof donationStatuses>;

export const donationTypes = [
  'monetary',
  'inKind',
  'promotional',
  'volunteer',
  'investment',
] as const;
export type DonationType = ArrayValues<typeof donationTypes>;
