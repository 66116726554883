/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access */

import { FormlyFieldConfig } from '@ngx-formly/core';

export const nonprofitQuestionnaire: FormlyFieldConfig[] = [
  {
    type: 'stepper',
    fieldGroup: [
      {
        props: { label: 'Organization & Impact' },
        fieldGroup: [
          {
            key: 'Keywords__c',
            type: 'select',
            props: {
              label: 'Which keywords are associated with your work?',
              options: [
                {
                  label: 'Access & Recreation',
                  value: 'Access & Recreation',
                },
                {
                  label: 'Activism & Awareness',
                  value: 'Activism & Awareness',
                },
                { label: 'Agriculture', value: 'Agriculture' },
                { label: 'Air Quality', value: 'Air Quality' },
                {
                  label: 'Alternative Transportation',
                  value: 'Alternative Transportation',
                },
                { label: 'Biodiversity', value: 'Biodiversity' },
                {
                  label: 'Blue Carbon Ecosystems',
                  value: 'Blue Carbon Ecosystems',
                },
                {
                  label: 'Clean Cookstoves',
                  value: 'Clean Cookstoves',
                },
                {
                  label: 'Clean Drinking Water',
                  value: 'Clean Drinking Water',
                },
                { label: 'Cleanups', value: 'Cleanups' },
                {
                  label: 'Community Development',
                  value: 'Community Development',
                },
                { label: 'Conservation', value: 'Conservation' },
                { label: 'Deforestation', value: 'Deforestation' },
                {
                  label: 'Disaster Mitigation',
                  value: 'Disaster Mitigation',
                },
                {
                  label: 'Drought Mitigation',
                  value: 'Drought Mitigation',
                },
                {
                  label: 'Economic Development',
                  value: 'Economic Development',
                },
                { label: 'Energy Poverty', value: 'Energy Poverty' },
                {
                  label: 'Environmental Education',
                  value: 'Environmental Education',
                },
                {
                  label: 'Environmental Justice',
                  value: 'Environmental Justice',
                },
                {
                  label: 'Family Planning Services',
                  value: 'Family Planning Services',
                },
                { label: 'Food Banks', value: 'Food Banks' },
                { label: 'Food Security', value: 'Food Security' },
                { label: 'Food Waste', value: 'Food Waste' },
                { label: 'Fossil Fuels', value: 'Fossil Fuels' },
                { label: 'Gender Equity', value: 'Gender Equity' },
                { label: 'Human Health', value: 'Human Health' },
                {
                  label: 'Indigenous Communities',
                  value: 'Indigenous Communities',
                },
                { label: 'Land Rights', value: 'Land Rights' },
                {
                  label: 'Local Food Systems',
                  value: 'Local Food Systems',
                },
                { label: 'Marine Life', value: 'Marine Life' },
                {
                  label: 'Ocean Conservation',
                  value: 'Ocean Conservation',
                },
                { label: 'Offsets', value: 'Offsets' },
                {
                  label: 'Plastic Pollution',
                  value: 'Plastic Pollution',
                },
                { label: 'Policy & Law', value: 'Policy & Law' },
                { label: 'Pollinators', value: 'Pollinators' },
                { label: 'Poverty', value: 'Poverty' },
                { label: 'Public Lands', value: 'Public Lands' },
                { label: 'Recycling', value: 'Recycling' },
                {
                  label: 'Renewable Energy',
                  value: 'Renewable Energy',
                },
                { label: 'Rewilding', value: 'Rewilding' },
                { label: 'Sanitation', value: 'Sanitation' },
                { label: 'School Food', value: 'School Food' },
                { label: 'Soil Health', value: 'Soil Health' },
                { label: 'Solar Energy', value: 'Solar Energy' },
                { label: 'STEM', value: 'STEM' },
                {
                  label: 'Sustainable Fishing',
                  value: 'Sustainabe Fishing',
                },
                {
                  label: 'Sustainable Cities',
                  value: 'Sustainable Cities',
                },
                {
                  label: 'Sustainable Eating',
                  value: 'Sustainable Eating',
                },
                {
                  label: 'Sustainable Tourism',
                  value: 'Sustainable Tourism',
                },
                { label: 'Textiles', value: 'Textiles' },
                { label: 'Toxins', value: 'Toxins' },
                { label: 'Tree Planting', value: 'Tree Planting' },
                { label: 'Urban Gardens', value: 'Urban Gardens' },
                { label: 'Voting', value: 'Voting' },
                { label: 'Water Quality', value: 'Water Quality' },
                { label: 'Wildfires', value: 'Wildfires' },
                {
                  label: 'Wildlife Education',
                  value: 'Wildlife Education',
                },
                {
                  label: 'Wildlife Conservation',
                  value: 'Wildlife Conservation',
                },
                {
                  label: 'Wildlife Rehabilitation',
                  value: 'Wildlife Rehabilitation',
                },
                { label: 'Wind Energy', value: 'Wind Energy' },
                {
                  label: "Women's Education",
                  value: "Women's Education",
                },
                { label: 'Youth', value: 'Youth' },
              ],
              multiple: true,
              placeholder: 'Select keywords...',
              required: true,
            },
          },
          {
            key: 'Achievement_1__c',
            type: 'textarea',
            props: {
              label: 'Largest / most impactful achievement in the last year',
              score: 1,
              maxLength: 255,
              required: true,
            },
          },
          {
            key: 'Achievement_2__c',
            type: 'textarea',
            hideExpression: (model: any) => !model.Achievement_1__c,
            props: {
              label:
                '2nd largest / most impactful achievement in the last year',
              score: 1,
              maxLength: 255,
            },
          },
          {
            key: 'Achievement_3__c',
            type: 'textarea',
            hideExpression: (model: any) => !model.Achievement_2__c,
            props: {
              label:
                '3rd largest / most impactful achievement in the last year',
              score: 1,
              maxLength: 255,
            },
          },
          {
            key: 'Annual_Report_Published__c',
            type: 'radio',
            props: {
              label:
                'Do you publish an annual report containing key impact metrics and program achievements?',
              score: 3,
              options: [
                { label: 'Yes', value: 'Yes', score: 1 },
                { label: 'No', value: 'No' },
              ],
              required: true,
            },
          },
          {
            key: 'Files.Annual_Report',
            type: 'file',
            hideExpression: (model: any) =>
              model.Annual_Report_Published__c !== 'Yes',
            props: { label: 'Annual report', score: 2 },
          },
          {
            key: 'Files.Program_Monitoring_Plan',
            type: 'file',
            props: {
              label: 'Program monitoring and evaluation plan',
              score: 3,
            },
          },
          {
            key: 'Environmental_Justice__c',
            type: 'radio',
            props: {
              label:
                'Do you identify as an environmental justice organization?',
              options: [
                {
                  label:
                    'No; Social/justice concerns are not reflected in any programming',
                  value:
                    'No; Social/justice concerns are not reflected in any programming',
                },
                {
                  label:
                    'Yes; Social/justice concerns are integrated into some programmatic work',
                  value:
                    'Yes; Social/justice concerns are integrated into some programmatic work',
                },
                {
                  label:
                    'Yes; Social/justice concerns are acknowledged by our organization but do not play into programmatic work',
                  value:
                    'Yes; Social/justice concerns are acknowledged by our organization but do not play into programmatic work',
                  score: 1,
                },
                {
                  label:
                    'Yes; Social/justice concerns are integrated into most or all programmatic work',
                  value:
                    'Yes; Social/justice concerns are integrated into most or all programmatic work',
                  score: 3,
                },
              ],
              required: true,
            },
          },
          {
            key: 'Impact_Country__c',
            type: 'select',
            props: {
              label: 'Which countries does your work impact?',
              options: [
                { label: 'Afghanistan', value: 'Afghanistan' },
                { label: 'Åland Islands', value: 'Åland Islands' },
                { label: 'Albania', value: 'Albania' },
                { label: 'Algeria', value: 'Algeria' },
                { label: 'American Samoa', value: 'American Samoa' },
                { label: 'Andorra', value: 'Andorra' },
                { label: 'Angola', value: 'Angola' },
                { label: 'Anguilla', value: 'Anguilla' },
                { label: 'Antarctica', value: 'Antarctica' },
                {
                  label: 'Antigua and Barbuda',
                  value: 'Antigua and Barbuda',
                },
                { label: 'Argentina', value: 'Argentina' },
                { label: 'Armenia', value: 'Armenia' },
                { label: 'Aruba', value: 'Aruba' },
                { label: 'Australia', value: 'Australia' },
                { label: 'Austria', value: 'Austria' },
                { label: 'Azerbaijan', value: 'Azerbaijan' },
                { label: 'Bahamas', value: 'Bahamas' },
                { label: 'Bahrain', value: 'Bahrain' },
                { label: 'Bangladesh', value: 'Bangladesh' },
                { label: 'Barbados', value: 'Barbados' },
                { label: 'Belarus', value: 'Belarus' },
                { label: 'Belgium', value: 'Belgium' },
                { label: 'Belize', value: 'Belize' },
                { label: 'Benin', value: 'Benin' },
                { label: 'Bermuda', value: 'Bermuda' },
                { label: 'Bhutan', value: 'Bhutan' },
                {
                  label: 'Bolivia (Plurinational State of)',
                  value: 'Bolivia (Plurinational State of)',
                },
                {
                  label: 'Bonaire, Sint Eustatius and Saba',
                  value: 'Bonaire, Sint Eustatius and Saba',
                },
                {
                  label: 'Bosnia and Herzegovina',
                  value: 'Bosnia and Herzegovina',
                },
                { label: 'Botswana', value: 'Botswana' },
                { label: 'Bouvet Island', value: 'Bouvet Island' },
                { label: 'Brazil', value: 'Brazil' },
                {
                  label: 'British Indian Ocean Territory',
                  value: 'British Indian Ocean Territory',
                },
                {
                  label: 'Brunei Darussalam',
                  value: 'Brunei Darussalam',
                },
                { label: 'Bulgaria', value: 'Bulgaria' },
                { label: 'Burkina Faso', value: 'Burkina Faso' },
                { label: 'Burundi', value: 'Burundi' },
                { label: 'Cabo Verde', value: 'Cabo Verde' },
                { label: 'Cambodia', value: 'Cambodia' },
                { label: 'Cameroon', value: 'Cameroon' },
                { label: 'Canada', value: 'Canada' },
                { label: 'Cayman Islands', value: 'Cayman Islands' },
                {
                  label: 'Central African Republic',
                  value: 'Central African Republic',
                },
                { label: 'Chad', value: 'Chad' },
                { label: 'Chile', value: 'Chile' },
                { label: 'China', value: 'China' },
                {
                  label: 'Christmas Island',
                  value: 'Christmas Island',
                },
                {
                  label: 'Cocos (Keeling) Islands',
                  value: 'Cocos (Keeling) Islands',
                },
                { label: 'Colombia', value: 'Colombia' },
                { label: 'Comoros', value: 'Comoros' },
                { label: 'Congo', value: 'Congo' },
                {
                  label: 'Congo (the Democratic Republic of the)',
                  value: 'Congo (the Democratic Republic of the)',
                },
                { label: 'Cook Islands', value: 'Cook Islands' },
                { label: 'Costa Rica', value: 'Costa Rica' },
                { label: "Côte d'Ivoire", value: "Côte d'Ivoire" },
                { label: 'Croatia', value: 'Croatia' },
                { label: 'Cuba', value: 'Cuba' },
                { label: 'Curaçao', value: 'Curaçao' },
                { label: 'Cyprus', value: 'Cyprus' },
                { label: 'Czechia', value: 'Czechia' },
                { label: 'Denmark', value: 'Denmark' },
                { label: 'Djibouti', value: 'Djibouti' },
                { label: 'Dominica', value: 'Dominica' },
                {
                  label: 'Dominican Republic',
                  value: 'Dominican Republic',
                },
                { label: 'Ecuador', value: 'Ecuador' },
                { label: 'Egypt', value: 'Egypt' },
                { label: 'El Salvador', value: 'El Salvador' },
                {
                  label: 'Equatorial Guinea',
                  value: 'Equatorial Guinea',
                },
                { label: 'Eritrea', value: 'Eritrea' },
                { label: 'Estonia', value: 'Estonia' },
                { label: 'Eswatini', value: 'Eswatini' },
                { label: 'Ethiopia', value: 'Ethiopia' },
                {
                  label: 'Falkland Islands (Malvinas)',
                  value: 'Falkland Islands (Malvinas)',
                },
                { label: 'Faroe Islands', value: 'Faroe Islands' },
                { label: 'Fiji', value: 'Fiji' },
                { label: 'Finland', value: 'Finland' },
                { label: 'France', value: 'France' },
                { label: 'French Guiana', value: 'French Guiana' },
                {
                  label: 'French Polynesia',
                  value: 'French Polynesia',
                },
                {
                  label: 'French Southern Territories',
                  value: 'French Southern Territories',
                },
                { label: 'Gabon', value: 'Gabon' },
                { label: 'Gambia', value: 'Gambia' },
                { label: 'Georgia', value: 'Georgia' },
                { label: 'Germany', value: 'Germany' },
                { label: 'Ghana', value: 'Ghana' },
                { label: 'Gibraltar', value: 'Gibraltar' },
                { label: 'Greece', value: 'Greece' },
                { label: 'Greenland', value: 'Greenland' },
                { label: 'Grenada', value: 'Grenada' },
                { label: 'Guadeloupe', value: 'Guadeloupe' },
                { label: 'Guam', value: 'Guam' },
                { label: 'Guatemala', value: 'Guatemala' },
                { label: 'Guernsey', value: 'Guernsey' },
                { label: 'Guinea', value: 'Guinea' },
                { label: 'Guinea-Bissau', value: 'Guinea-Bissau' },
                { label: 'Guyana', value: 'Guyana' },
                { label: 'Haiti', value: 'Haiti' },
                {
                  label: 'Heard Island and McDonald Islands',
                  value: 'Heard Island and McDonald Islands',
                },
                { label: 'Holy See', value: 'Holy See' },
                { label: 'Honduras', value: 'Honduras' },
                { label: 'Hong Kong', value: 'Hong Kong' },
                { label: 'Hungary', value: 'Hungary' },
                { label: 'Iceland', value: 'Iceland' },
                { label: 'India', value: 'India' },
                { label: 'Indonesia', value: 'Indonesia' },
                {
                  label: 'Iran (Islamic Republic of)',
                  value: 'Iran (Islamic Republic of)',
                },
                { label: 'Iraq', value: 'Iraq' },
                { label: 'Ireland', value: 'Ireland' },
                { label: 'Isle of Man', value: 'Isle of Man' },
                { label: 'Israel', value: 'Israel' },
                { label: 'Italy', value: 'Italy' },
                { label: 'Jamaica', value: 'Jamaica' },
                { label: 'Japan', value: 'Japan' },
                { label: 'Jersey', value: 'Jersey' },
                { label: 'Jordan', value: 'Jordan' },
                { label: 'Kazakhstan', value: 'Kazakhstan' },
                { label: 'Kenya', value: 'Kenya' },
                { label: 'Kiribati', value: 'Kiribati' },
                {
                  label: "Korea (the Democratic People's Republic of)",
                  value: "Korea (the Democratic People's Republic of)",
                },
                {
                  label: 'Korea (the Republic of)',
                  value: 'Korea (the Republic of)',
                },
                { label: 'Kuwait', value: 'Kuwait' },
                { label: 'Kyrgyzstan', value: 'Kyrgyzstan' },
                {
                  label: "Lao People's Democratic Republic",
                  value: "Lao People's Democratic Republic",
                },
                { label: 'Latvia', value: 'Latvia' },
                { label: 'Lebanon', value: 'Lebanon' },
                { label: 'Lesotho', value: 'Lesotho' },
                { label: 'Liberia', value: 'Liberia' },
                { label: 'Libya', value: 'Libya' },
                { label: 'Liechtenstein', value: 'Liechtenstein' },
                { label: 'Lithuania', value: 'Lithuania' },
                { label: 'Luxembourg', value: 'Luxembourg' },
                { label: 'Macao', value: 'Macao' },
                { label: 'Madagascar', value: 'Madagascar' },
                { label: 'Malawi', value: 'Malawi' },
                { label: 'Malaysia', value: 'Malaysia' },
                { label: 'Maldives', value: 'Maldives' },
                { label: 'Mali', value: 'Mali' },
                { label: 'Malta', value: 'Malta' },
                {
                  label: 'Marshall Islands',
                  value: 'Marshall Islands',
                },
                { label: 'Martinique', value: 'Martinique' },
                { label: 'Mauritania', value: 'Mauritania' },
                { label: 'Mauritius', value: 'Mauritius' },
                { label: 'Mayotte', value: 'Mayotte' },
                { label: 'Mexico', value: 'Mexico' },
                {
                  label: 'Micronesia (Federated States of)',
                  value: 'Micronesia (Federated States of)',
                },
                {
                  label: 'Moldova (the Republic of)',
                  value: 'Moldova (the Republic of)',
                },
                { label: 'Monaco', value: 'Monaco' },
                { label: 'Mongolia', value: 'Mongolia' },
                { label: 'Montenegro', value: 'Montenegro' },
                { label: 'Montserrat', value: 'Montserrat' },
                { label: 'Morocco', value: 'Morocco' },
                { label: 'Mozambique', value: 'Mozambique' },
                { label: 'Myanmar', value: 'Myanmar' },
                { label: 'Namibia', value: 'Namibia' },
                { label: 'Nauru', value: 'Nauru' },
                { label: 'Nepal', value: 'Nepal' },
                {
                  label: 'Netherlands (Kingdom of the)',
                  value: 'Netherlands (Kingdom of the)',
                },
                { label: 'New Caledonia', value: 'New Caledonia' },
                { label: 'New Zealand', value: 'New Zealand' },
                { label: 'Nicaragua', value: 'Nicaragua' },
                { label: 'Niger', value: 'Niger' },
                { label: 'Nigeria', value: 'Nigeria' },
                { label: 'Niue', value: 'Niue' },
                { label: 'Norfolk Island', value: 'Norfolk Island' },
                { label: 'North Macedonia', value: 'North Macedonia' },
                {
                  label: 'Northern Mariana Islands',
                  value: 'Northern Mariana Islands',
                },
                { label: 'Norway', value: 'Norway' },
                { label: 'Oman', value: 'Oman' },
                { label: 'Pakistan', value: 'Pakistan' },
                { label: 'Palau', value: 'Palau' },
                {
                  label: 'Palestine, State of',
                  value: 'Palestine, State of',
                },
                { label: 'Panama', value: 'Panama' },
                {
                  label: 'Papua New Guinea',
                  value: 'Papua New Guinea',
                },
                { label: 'Paraguay', value: 'Paraguay' },
                { label: 'Peru', value: 'Peru' },
                { label: 'Philippines', value: 'Philippines' },
                { label: 'Pitcairn', value: 'Pitcairn' },
                { label: 'Poland', value: 'Poland' },
                { label: 'Portugal', value: 'Portugal' },
                { label: 'Puerto Rico', value: 'Puerto Rico' },
                { label: 'Qatar', value: 'Qatar' },
                { label: 'Réunion', value: 'Réunion' },
                { label: 'Romania', value: 'Romania' },
                {
                  label: 'Russian Federation',
                  value: 'Russian Federation',
                },
                { label: 'Rwanda', value: 'Rwanda' },
                {
                  label: 'Saint Barthélemy',
                  value: 'Saint Barthélemy',
                },
                {
                  label: 'Saint Helena, Ascension and Tristan da Cunha',
                  value: 'Saint Helena, Ascension and Tristan da Cunha',
                },
                {
                  label: 'Saint Kitts and Nevis',
                  value: 'Saint Kitts and Nevis',
                },
                { label: 'Saint Lucia', value: 'Saint Lucia' },
                {
                  label: 'Saint Martin (French part)',
                  value: 'Saint Martin (French part)',
                },
                {
                  label: 'Saint Pierre and Miquelon',
                  value: 'Saint Pierre and Miquelon',
                },
                {
                  label: 'Saint Vincent and the Grenadines',
                  value: 'Saint Vincent and the Grenadines',
                },
                { label: 'Samoa', value: 'Samoa' },
                { label: 'San Marino', value: 'San Marino' },
                {
                  label: 'Sao Tome and Principe',
                  value: 'Sao Tome and Principe',
                },
                { label: 'Saudi Arabia', value: 'Saudi Arabia' },
                { label: 'Senegal', value: 'Senegal' },
                { label: 'Serbia', value: 'Serbia' },
                { label: 'Seychelles', value: 'Seychelles' },
                { label: 'Sierra Leone', value: 'Sierra Leone' },
                { label: 'Singapore', value: 'Singapore' },
                {
                  label: 'Sint Maarten (Dutch part)',
                  value: 'Sint Maarten (Dutch part)',
                },
                { label: 'Slovakia', value: 'Slovakia' },
                { label: 'Slovenia', value: 'Slovenia' },
                { label: 'Solomon Islands', value: 'Solomon Islands' },
                { label: 'Somalia', value: 'Somalia' },
                { label: 'South Africa', value: 'South Africa' },
                {
                  label: 'South Georgia and the South Sandwich Islands',
                  value: 'South Georgia and the South Sandwich Islands',
                },
                { label: 'South Sudan', value: 'South Sudan' },
                { label: 'Spain', value: 'Spain' },
                { label: 'Sri Lanka', value: 'Sri Lanka' },
                { label: 'Sudan', value: 'Sudan' },
                { label: 'Suriname', value: 'Suriname' },
                {
                  label: 'Svalbard and Jan Mayen',
                  value: 'Svalbard and Jan Mayen',
                },
                { label: 'Sweden', value: 'Sweden' },
                { label: 'Switzerland', value: 'Switzerland' },
                {
                  label: 'Syrian Arab Republic',
                  value: 'Syrian Arab Republic',
                },
                { label: 'Taiwan', value: 'Taiwan' },
                { label: 'Tajikistan', value: 'Tajikistan' },
                {
                  label: 'Tanzania, the United Republic of',
                  value: 'Tanzania, the United Republic of',
                },
                { label: 'Thailand', value: 'Thailand' },
                { label: 'Timor-Leste', value: 'Timor-Leste' },
                { label: 'Togo', value: 'Togo' },
                { label: 'Tokelau', value: 'Tokelau' },
                { label: 'Tonga', value: 'Tonga' },
                {
                  label: 'Trinidad and Tobago',
                  value: 'Trinidad and Tobago',
                },
                { label: 'Tunisia', value: 'Tunisia' },
                { label: 'Türkiye', value: 'Türkiye' },
                { label: 'Turkmenistan', value: 'Turkmenistan' },
                {
                  label: 'Turks and Caicos Islands',
                  value: 'Turks and Caicos Islands',
                },
                { label: 'Tuvalu', value: 'Tuvalu' },
                { label: 'Uganda', value: 'Uganda' },
                { label: 'Ukraine', value: 'Ukraine' },
                {
                  label: 'United Arab Emirates',
                  value: 'United Arab Emirates',
                },
                {
                  label: 'United Kingdom of Great Britain and Northern Ireland',
                  value: 'United Kingdom of Great Britain and Northern Ireland',
                },
                {
                  label: 'United States of America',
                  value: 'United States of America',
                },
                {
                  label: 'United States Minor Outlying Islands',
                  value: 'United States Minor Outlying Islands',
                },
                { label: 'Uruguay', value: 'Uruguay' },
                { label: 'Uzbekistan', value: 'Uzbekistan' },
                { label: 'Vanuatu', value: 'Vanuatu' },
                {
                  label: 'Venezuela (Bolivarian Republic of)',
                  value: 'Venezuela (Bolivarian Republic of)',
                },
                { label: 'Viet Nam', value: 'Viet Nam' },
                {
                  label: 'Virgin Islands (British)',
                  value: 'Virgin Islands (British)',
                },
                {
                  label: 'Virgin Islands (U.S.)',
                  value: 'Virgin Islands (U.S.)',
                },
                {
                  label: 'Wallis and Futuna',
                  value: 'Wallis and Futuna',
                },
                { label: 'Western Sahara', value: 'Western Sahara' },
                { label: 'Yemen', value: 'Yemen' },
                { label: 'Zambia', value: 'Zambia' },
                { label: 'Zimbabwe', value: 'Zimbabwe' },
              ],
              multiple: true,
              placeholder: 'Select countries...',
              required: true,
            },
          },
          {
            key: 'Impact_State__c',
            type: 'select',
            hideExpression: (model: any) =>
              !model.Impact_Country__c?.includes('United States'),
            props: {
              label: 'Which US states does your work impact?',
              options: [
                { label: 'All', value: 'All' },
                { label: 'None', value: 'None' },
                { label: 'AK', value: 'AK' },
                { label: 'AL', value: 'AL' },
                { label: 'American Samoa', value: 'American Samoa' },
                { label: 'AR', value: 'AR' },
                { label: 'AZ', value: 'AZ' },
                { label: 'CA', value: 'CA' },
                { label: 'CO', value: 'CO' },
                { label: 'CT', value: 'CT' },
                { label: 'DE', value: 'DE' },
                {
                  label: 'District of Columbia',
                  value: 'District of Columbia',
                },
                { label: 'FL', value: 'FL' },
                { label: 'GA', value: 'GA' },
                { label: 'Guam', value: 'Guam' },
                { label: 'HI', value: 'HI' },
                { label: 'IA', value: 'IA' },
                { label: 'ID', value: 'ID' },
                { label: 'IL', value: 'IL' },
                { label: 'IN', value: 'IN' },
                { label: 'KS', value: 'KS' },
                { label: 'KY', value: 'KY' },
                { label: 'LA', value: 'LA' },
                { label: 'MA', value: 'MA' },
                { label: 'MD', value: 'MD' },
                { label: 'ME', value: 'ME' },
                { label: 'MI', value: 'MI' },
                { label: 'MN', value: 'MN' },
                { label: 'MO', value: 'MO' },
                { label: 'MS', value: 'MS' },
                { label: 'MT', value: 'MT' },
                { label: 'NE', value: 'NE' },
                { label: 'NH', value: 'NH' },
                { label: 'NJ', value: 'NJ' },
                { label: 'NM', value: 'NM' },
                {
                  label: 'Northern Mariana Islands',
                  value: 'Northern Mariana Islands',
                },
                { label: 'NC', value: 'NC' },
                { label: 'ND', value: 'ND' },
                { label: 'NV', value: 'NV' },
                { label: 'NY', value: 'NY' },
                { label: 'OH', value: 'OH' },
                { label: 'OK', value: 'OK' },
                { label: 'OR', value: 'OR' },
                { label: 'PA', value: 'PA' },
                { label: 'Puerto Rico', value: 'Puerto Rico' },
                { label: 'RI', value: 'RI' },
                { label: 'SC', value: 'SC' },
                { label: 'SD', value: 'SD' },
                { label: 'TN', value: 'TN' },
                { label: 'TX', value: 'TX' },
                {
                  label: 'U.S. Virgin Islands',
                  value: 'U.S. Virgin Islands',
                },
                { label: 'UT', value: 'UT' },
                { label: 'VA', value: 'VA' },
                { label: 'VT', value: 'VT' },
                { label: 'WA', value: 'WA' },
                { label: 'WI', value: 'WI' },
                { label: 'WV', value: 'WV' },
                { label: 'WY', value: 'WY' },
              ],
              multiple: true,
              placeholder: 'Select states...',
            },
          },
          {
            key: 'Impact_Translations_Possible__c',
            type: 'radio',
            props: {
              label:
                'Can you translate funding amounts into impact? (For example, €1 plants 1 tree; ¥100 restores 1 acre of wetland)',
              options: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ],
              required: true,
            },
          },
          {
            key: 'Impact_Translations__c',
            type: 'textarea',
            hideExpression: (model: any) =>
              model.Impact_Translations_Possible__c !== 'Yes',
            props: {
              label: 'Please identify metrics with currency noted',
              maxLength: 255,
              required: true,
            },
          },
          {
            key: 'Staff_Working_Elsewhere__c',
            type: 'radio',
            props: {
              label:
                'Do you have employees located in geographical areas other than your main offices?',
              options: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ],
              required: true,
            },
          },
          {
            key: 'Staff_Alt_Locations__c',
            type: 'textarea',
            hideExpression: (model: any) =>
              model.Staff_Working_Elsewhere__c !== 'Yes',
            props: {
              label:
                'Where are they predominantly located? (list multiple locations if applicable)',
              maxLength: 1000,
              required: true,
            },
          },
          {
            key: 'Chapter_Network__c',
            type: 'radio',
            props: {
              label: 'Do you have a chapter or network model?',
              options: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ],
              required: true,
            },
          },
          {
            key: 'Chapter_Network_Location__c',
            hideExpression: (model: any) => model.Chapter_Network__c !== 'Yes',
            type: 'textarea',
            props: {
              label:
                'Where are your main chapter/networks geographically located?',
              maxLength: 1000,
              required: true,
            },
          },
          {
            key: 'Community_Based__c',
            type: 'radio',
            props: {
              label:
                'Are you a community-based organization?  A community-based organization is defined as an organization that is driven by self-identified community of residents, is sited in the community it serves, prioritizes issues identified by the community, and involves the community in solutions.',
              options: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ],
              required: true,
            },
          },
          {
            key: 'Employee_Representation__c',
            type: 'radio',
            props: {
              label:
                'If a community-based organization working with marginalized communities, are your employees representative of the community you serve or collaborate? A marginalized community is a self-identified group of people that face or have faced discriminatory exclusion from natural, social, political, or economic resources.',
              options: [
                {
                  label: 'Not representative',
                  value: 'Not representative',
                },
                {
                  label: 'Minority representation',
                  value: 'Minority representation',
                  score: 1,
                },
                {
                  label: 'Majority representation',
                  value: 'Majority representation',
                  score: 2,
                },
                { label: 'N/A', value: 'N/A' },
              ],
              required: true,
            },
          },
          {
            key: 'Staff_On_The_Ground__c',
            type: 'radio',
            props: {
              label:
                'Does your organization have on-the-ground staff in the locations where your impact is occurring?',
              options: [
                { label: 'No', value: 'No' },
                {
                  label: 'Yes, in a minority of locations',
                  value: 'Yes, in a minority of locations',
                  score: 1,
                },
                {
                  label: 'Yes, in a majority of locations',
                  value: 'Yes, in a majority of locations',
                  score: 2,
                },
                {
                  label: 'Yes, in all locations',
                  value: 'Yes, in all locations',
                  score: 3,
                },
              ],
              required: true,
            },
          },
          {
            key: 'Files.Support_Letter',
            type: 'file',
            props: {
              label:
                'Support letter from a large or primary community your organization serves or collaborates with',
              score: 3,
            },
          },
          {
            key: 'BIPOC__c',
            type: 'radio',
            props: {
              label:
                'Do you identify as a Person of Color (POC)-, Black-, or Indigenous-led organization?',
              options: [
                { label: 'Yes', value: 'Yes', score: 3 },
                { label: 'No', value: 'No' },
              ],
              required: true,
            },
          },
          {
            key: 'Files.Accreditation',
            type: 'file',
            props: { label: 'Accreditations obtained', maxFiles: 3, score: 1 },
          },
          {
            key: 'Conflict_Policy__c',
            type: 'radio',
            props: {
              label: 'Do you have a Conflicts of Interest Policy?',
              options: [
                { label: 'Yes', value: 'Yes', score: 1 },
                { label: 'No', value: 'No' },
              ],
              required: true,
            },
          },
          {
            key: 'Files.Conflict_Policy',
            type: 'file',
            hideExpression: (model: any) => model.Conflict_Policy__c !== 'Yes',
            props: { label: 'Conflicts of Interest Policy', score: 2 },
          },
          {
            key: 'Conflict_Executive__c',
            type: 'radio',
            props: {
              label:
                'Do you have any conflicts of interest at the executive level?',
              options: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No', score: 3 },
              ],
              required: true,
            },
          },
          {
            key: 'Staff_Published__c',
            type: 'radio',
            props: {
              label: 'Do you publish a current list of key staff?',
              options: [
                { label: 'Yes', value: 'Yes', score: 1 },
                { label: 'No', value: 'No' },
              ],
              required: true,
            },
          },
          {
            key: 'Files.Staff_List',
            type: 'file',
            hideExpression: (model: any) => model.Staff_Published__c !== 'Yes',
            props: { label: 'List of key staff', score: 1 },
          },
          {
            key: 'Conflict_Board__c',
            type: 'radio',
            props: {
              label:
                'Do you have any conflicts of interest at the board level?',
              options: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No', score: 3 },
              ],
              required: true,
            },
          },
          {
            key: 'Board_Diversity__c',
            type: 'radio',
            props: {
              label:
                'Do you have a diverse board composition?  Diversity is inclusive of racial, ethnic, cultural, gender, ability categories.',
              options: [
                {
                  label: 'No; Board has no diversity as described',
                  value: 'No; Board has no diversity as described',
                },
                {
                  label:
                    'Yes; Board has a minority of board seats representing diverse stakeholders',
                  value:
                    'Yes; Board has a minority of board seats representing diverse stakeholders',
                  score: 2,
                },
                {
                  label:
                    "Yes; Board has a majority of board seats representing diverse stakeholders with involvement that impacts the organization's DEIJ strategy",
                  value:
                    "Yes; Board has a majority of board seats representing diverse stakeholders with involvement that impacts the organization's DEIJ strategy",
                  score: 3,
                },
              ],
              required: true,
            },
          },
          {
            key: 'Files.Board_Meeting_Minutes',
            type: 'file',
            props: { label: 'Board meeting minutes (if published)', score: 1 },
          },
          {
            key: 'Board_Published__c',
            type: 'radio',
            props: {
              label: 'Do you publish a current list of board members?',
              options: [
                { label: 'Yes', value: 'Yes', score: 1 },
                { label: 'No', value: 'No' },
              ],
              required: true,
            },
          },
          {
            key: 'Files.Board_Members',
            type: 'file',
            hideExpression: (model: any) => model.Board_Published__c !== 'Yes',
            props: { label: 'List of board members', score: 1 },
          },
          {
            key: 'Whistleblower_Policy__c',
            type: 'radio',
            props: {
              label: 'Do you have a whistleblower policy?',
              options: [
                { label: 'Yes', value: 'Yes', score: 1 },
                { label: 'No', value: 'No' },
              ],
              required: true,
            },
          },
          {
            key: 'Files.Whistleblower_Policy',
            type: 'file',
            hideExpression: (model: any) =>
              model.Whistleblower_Policy__c !== 'Yes',
            props: { label: 'Whistleblower policy', score: 1 },
          },
          {
            key: 'Diversity_Statement__c',
            type: 'textarea',
            props: {
              label: 'Diversity/equity statement',
              score: 1,
              maxLength: 255,
            },
          },
          {
            key: 'Crisis_Communication_Strategy__c',
            type: 'textarea',
            props: {
              label: 'Crisis communications strategy',
              score: 1,
              maxLength: 255,
            },
          },
          {
            key: 'Privacy_Policy__c',
            type: 'radio',
            props: {
              label: 'Do you have a donor privacy policy?',
              options: [
                { label: 'Yes', value: 'Yes', score: 1 },
                { label: 'No', value: 'No' },
              ],
              required: true,
            },
          },
          {
            key: 'Files.Donor_Privacy_Policy',
            type: 'file',
            hideExpression: (model: any) => model.Privacy_Policy__c !== 'Yes',
            props: { label: 'Donor privacy policy', score: 1 },
          },
          {
            key: 'Operating_Budget__c',
            type: 'currency',
            props: {
              label:
                'What is your current fiscal year operating budget in US$?',
              score: 1,
              maxIntegerDigits: 18,
              maxFractionDigits: 0,
              maxLength: 18,
              type: 'number',
              required: true,
            },
          },
          {
            key: 'Files.Independent_Financial_Audit',
            type: 'file',
            props: { label: 'Independent financial audit', score: 3 },
          },
          {
            key: 'Expense_Percent_Program__c',
            type: 'radio',
            props: {
              label:
                'What percentage of your operating budget goes to program expenses? = Average Program Expenses ÷ Average Total Expenses (For US organizations, this is Form 990 Part IX line 25B ÷ line 25A).',
              options: [
                { label: '0-39%', value: '0-39%' },
                { label: '40-59%', value: '40-59%', score: 1 },
                { label: '60-79%', value: '60-79%', score: 2 },
                { label: '80-100%', value: '80-100%', score: 3 },
              ],
              required: true,
            },
          },
          {
            key: 'Liability_Asset_Ratio__c',
            type: 'radio',
            props: {
              label:
                'What is your liability:asset ratio? (For US organizations: 990 Part X line 26 ÷  line 16).',
              options: [
                { label: '0-20%', value: '0-20%', score: 3 },
                { label: '20-40%', value: '20-40%', score: 2 },
                { label: '40-100%', value: '40-100%', score: 1 },
                { label: '>100%', value: '>100%' },
              ],
              required: true,
            },
          },
          {
            key: 'Expense_Percent_Admin__c',
            type: 'radio',
            props: {
              label:
                'What is your administrative expense percentage? = Average Administrative Expenses ÷ Average Total Expenses (For US organizations:  990 Part IX line 25C ÷ line 25A)',
              options: [
                { label: '0-20%', value: '0-20%', score: 3 },
                { label: '20-30%', value: '20-30%', score: 2 },
                { label: '30-40%', value: '30-40%', score: 1 },
                { label: '>40%', value: '>40%' },
              ],
              required: true,
            },
          },
          {
            key: 'Expense_Percent_Fundraising__c',
            type: 'radio',
            props: {
              label:
                'What is your average fundraising expense percentage? = Average Fundraising Expenses ÷ Average Total Expenses (For US organizations: 990 Part IX line 25D ÷ line 25A)',
              options: [
                { label: '0-10%', value: '0-10%', score: 3 },
                { label: '10-15%', value: '10-15%', score: 2 },
                { label: '15-25%', value: '15-25%', score: 1 },
                { label: '>25%', value: '>25%' },
              ],
              required: true,
            },
          },
        ],
      },
      {
        props: { label: 'Corporate Partnerships' },
        fieldGroup: [
          {
            key: 'Environmental_Challenges__c',
            type: 'textarea',
            props: {
              label:
                'What is the biggest challenge you currently face as an environment nonprofit and how can our members help you address that problem?',
              maxLength: 3000,
            },
          },
          {
            key: 'Chapter_Network_Engagement__c',
            type: 'textarea',
            props: {
              label:
                "If you have a chapter or network model, can corporate supporters engage with individual chapters/networks? For example, can donations be allocated to a specific chapter of a supporter's choosing?",
              maxLength: 1000,
            },
          },
          {
            key: 'Corp_Support__c',
            type: 'radio',
            props: {
              label:
                'Do you regularly receive corporate donations or other corporate support?',
              options: [
                { label: 'Yes', value: 'Yes', score: 1 },
                { label: 'No', value: 'No' },
              ],
              required: true,
            },
          },
          {
            key: 'Corp_Support_Methods__c',
            type: 'select',
            hideExpression: (model: any) => model.Corp_Support__c !== 'Yes',
            props: {
              label: 'Primary ways corporations are able to support you',
              options: [
                {
                  label: 'Advisory Councils',
                  value: 'Advisory Councils',
                  score: 1,
                },
                {
                  label: 'Board Service',
                  value: 'Board Service',
                  score: 1,
                },
                {
                  label: 'Monetary Support',
                  value: 'Monetary Support',
                  score: 1,
                },
                {
                  label: 'Event Sponsorship',
                  value: 'Event Sponsorship',
                  score: 1,
                },
                {
                  label: 'Product Donations',
                  value: 'Product Donations',
                  score: 1,
                },
                {
                  label: 'Volunteering and Employee Engagement',
                  value: 'Volunteering and Employee Engagement',
                  score: 1,
                },
                {
                  label: 'Services Donations',
                  value: 'Services Donations',
                  score: 1,
                },
                {
                  label: 'Social Media Posts',
                  value: 'Social Media Posts',
                  score: 1,
                },
                { label: 'Other', value: 'Other', score: 1 },
              ],
              multiple: true,
              placeholder: 'Select methods...',
            },
          },
          {
            key: 'Corp_Support_Exclusive__c',
            type: 'radio',
            props: {
              label:
                'In regards to corporate support, do you offer exclusive partnership opportunities to businesses representing specific industries or categories? (For example, you will only work with one beverage company as a corporate supporter at any given time)',
              options: [
                { label: 'Yes', value: 'Yes', score: 1 },
                { label: 'No', value: 'No' },
              ],
              required: true,
            },
          },
          {
            key: 'Corp_Support_Exclusive_Details__c',
            type: 'textarea',
            hideExpression: (model: any) =>
              model.Corp_Support_Exclusive__c !== 'Yes',
            props: { label: 'Please explain', maxLength: 1000 },
          },
          {
            key: 'Corp_Support_Interests__c',
            type: 'select',
            props: {
              label:
                'What type of corporate support is of most interest to you?',
              options: [
                {
                  label: 'Advisory Councils',
                  value: 'Advisory Councils',
                  score: 1,
                },
                {
                  label: 'Affiliated Certifications',
                  value: 'Affiliated Certifications',
                  score: 1,
                },
                {
                  label: 'Board Service',
                  value: 'Board Service',
                  score: 1,
                },
                {
                  label: 'Event Sponsorship',
                  value: 'Event Sponsorship',
                  score: 1,
                },
                { label: 'Financial', value: 'Financial', score: 1 },
                { label: 'Logo Use', value: 'Logo Use', score: 1 },
                {
                  label: 'Product Donations',
                  value: 'Product Donations',
                  score: 1,
                },
                {
                  label: 'Service Donations',
                  value: 'Service Donations',
                  score: 1,
                },
                {
                  label: 'Social Media Posts',
                  value: 'Social Media Posts',
                  score: 1,
                },
                {
                  label: 'Volunteer/Employee Engagement',
                  value: 'Volunteer/Employee Engagement',
                  score: 1,
                },
              ],
              multiple: true,
              placeholder: 'Select support...',
            },
          },
          {
            key: 'Corp_Support_Ideal__c',
            type: 'textarea',
            props: {
              label: 'Describe your ideal corporate supporter',
              maxLength: 1000,
            },
          },
          {
            key: 'Corp_Support_Interests_Non__c',
            type: 'textarea',
            props: {
              label:
                'Type of corporate supporters you would not be interested in working with',
              maxLength: 1000,
            },
          },
          {
            key: 'In_Kind__c',
            type: 'radio',
            props: {
              label:
                'Are you willing and able to accept in-kind donations of products or services?',
              options: [
                { label: 'Yes', value: 'Yes', score: 3 },
                { label: 'No', value: 'No' },
              ],
              required: true,
            },
          },
          {
            key: 'In_Kind_Useful__c',
            type: 'select',
            hideExpression: (model: any) => model.In_Kind__c !== 'Yes',
            props: {
              label:
                'What types of in-kind products and services are most useful?',
              options: [
                {
                  label: 'Agricultural/Horticultural Products',
                  value: 'Agricultural/Horticultural Products',
                },
                { label: 'Catering', value: 'Catering' },
                {
                  label: 'Cold Weather Clothing',
                  value: 'Cold Weather Clothing',
                },
                { label: 'Drinks', value: 'Drinks' },
                { label: 'Event Space', value: 'Event Space' },
                { label: 'Food Products', value: 'Food Products' },
                { label: 'Legal Services', value: 'Legal Services' },
                { label: 'Office Items', value: 'Office Items' },
                {
                  label: 'Outdoor & Bicycle Gear',
                  value: 'Outdoor & Bicycle Gear',
                },
                {
                  label: 'Personal Hygiene Products',
                  value: 'Personal Hygiene Products',
                },
                {
                  label: 'Products for Gifts/Auctions/Fundraising',
                  value: 'Products for Gifts/Auctions/Fundraising',
                },
                {
                  label: 'Science Equipment',
                  value: 'Science Equipment',
                },
                { label: 'Technology', value: 'Technology' },
                { label: 'Other Products', value: 'Other Products' },
              ],
              multiple: true,
              placeholder: 'Select items...',
            },
          },
          {
            key: 'Volunteer__c',
            type: 'radio',
            props: {
              label: 'Do you provide volunteer opportunities?',
              options: [
                { label: 'Yes', value: 'Yes', score: 3 },
                { label: 'No', value: 'No' },
              ],
              required: true,
            },
          },
          {
            key: 'Volunteer_Locations__c',
            type: 'textarea',
            hideExpression: (model: any) => model.Volunteer__c !== 'Yes',
            props: {
              label: 'In what geographical locations?',
              maxLength: 1000,
            },
          },
          {
            key: 'Volunteer_Pre_Scheduled__c',
            type: 'radio',
            hideExpression: (model: any) => model.Volunteer__c !== 'Yes',
            props: {
              label:
                'Do you provide pre-scheduled opportunities that are open to the general public?',
              options: [
                { label: 'Yes', value: 'Yes', score: 1 },
                { label: 'No', value: 'No' },
              ],
            },
          },
          {
            key: 'Volunteer_Private__c',
            type: 'radio',
            hideExpression: (model: any) => model.Volunteer__c !== 'Yes',
            props: {
              label: 'Do you provide private/custom volunteer opportunities?',
              options: [
                { label: 'Yes', value: 'Yes', score: 1 },
                { label: 'No', value: 'No' },
              ],
            },
          },
          {
            key: 'Volunteer_Private_Min_Participants__c',
            type: 'radio',
            hideExpression: (model: any) =>
              model.Volunteer_Private__c !== 'Yes',
            props: {
              label:
                'If there is a minimum number of participants, what is that minimum number?',
              options: [
                { label: 'No minimum', value: 'No minimum' },
                { label: '1-5', value: '1-5' },
                { label: '5-10', value: '5-10' },
                { label: '10-25', value: '10-25' },
                { label: '25-50', value: '25-50' },
                { label: '50+', value: '50+' },
              ],
            },
          },
          {
            key: 'Volunteer_Private_Cost__c',
            type: 'textarea',
            hideExpression: (model: any) =>
              model.Volunteer_Private__c !== 'Yes',
            props: {
              label:
                'If there is a cost to businesses, what are those amount(s)?',
              maxLength: 1000,
            },
          },
          {
            key: 'Volunteer_Skill_Based__c',
            type: 'radio',
            hideExpression: (model: any) => model.Volunteer__c !== 'Yes',
            props: {
              label: 'Do you seek skills-based volunteers?',
              options: [
                { label: 'Yes', value: 'Yes', score: 1 },
                { label: 'No', value: 'No' },
              ],
            },
          },
          {
            key: 'Volunteer_Skills__c',
            type: 'select',
            hideExpression: (model: any) =>
              model.Volunteer_Skill_Based__c !== 'Yes',
            props: {
              label: 'Which skills are most helpful?',
              options: [
                { label: 'Copy Writing', value: 'Copy Writing' },
                { label: 'Data Entry', value: 'Data Entry' },
                { label: 'Event Staff', value: 'Event Staff' },
                { label: 'Field Work', value: 'Field Work' },
                {
                  label: 'Financial Services',
                  value: 'Financial Services',
                },
                {
                  label: 'Forest Management',
                  value: 'Forest Management',
                },
                { label: 'Graphic Design', value: 'Graphic Design' },
                { label: 'Legal Services', value: 'Legal Services' },
                {
                  label: 'Photography & Videography',
                  value: 'Photography & Videography',
                },
                {
                  label: 'Social Media & Marketing Support',
                  value: 'Social Media & Marketing Support',
                },
                {
                  label: 'Website Assistance',
                  value: 'Website Assistance',
                },
                {
                  label: 'Youth Mentorship',
                  value: 'Youth Mentorship',
                },
                { label: 'Other', value: 'Other' },
              ],
              multiple: true,
              placeholder: 'Select skills...',
            },
          },
          {
            key: 'Seek_Unrestricted_Funding__c',
            type: 'radio',
            props: {
              label: 'Do you seek unrestricted/overhead funding?',
              options: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ],
              required: true,
            },
          },
          {
            key: 'Response_Time__c',
            type: 'radio',
            props: {
              label:
                'What is your usual response time when communicating with external partners/funders?',
              options: [
                { label: 'Not Applicable', value: 'Not Applicable' },
                { label: '1 day', value: '1 day', score: 3 },
                { label: '2 days', value: '2 days', score: 2 },
                { label: '3 days', value: '3 days', score: 1 },
                { label: '4+ days', value: '4+ days' },
              ],
              required: true,
            },
          },
          {
            key: 'Logo_Use__c',
            type: 'radio',
            props: {
              label: 'Do you allow business supporters to use your logo?',
              options: [
                { label: 'Yes', value: 'Yes', score: 3 },
                { label: 'No', value: 'No' },
              ],
              required: true,
            },
          },
          {
            key: 'Logo_Use_Cost__c',
            type: 'currency',
            hideExpression: (model: any) => model.Logo_Use__c !== 'Yes',
            props: {
              label:
                'If there a minimum donation required to use your logo, enter the amount',
              maxIntegerDigits: 18,
              maxFractionDigits: 0,
              maxLength: 18,
            },
          },
          {
            key: 'Logo_Use_Contract__c',
            type: 'radio',
            hideExpression: (model: any) => model.Logo_Use__c !== 'Yes',
            props: {
              label:
                'Are businesses required to sign a contract to use your logo?',
              options: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ],
            },
          },
          {
            key: 'Name_Use__c',
            type: 'radio',
            props: {
              label: 'Do you allow business supporters to use your name?',
              options: [
                { label: 'Yes', value: 'Yes', score: 3 },
                { label: 'No', value: 'No' },
              ],
              required: true,
            },
          },
          {
            key: 'Name_Use_Cost__c',
            type: 'currency',
            hideExpression: (model: any) => model.Name_Use__c !== 'Yes',
            props: {
              label:
                'If there a minimum donation required to use your name, enter the amount',
              maxIntegerDigits: 18,
              maxFractionDigits: 0,
              maxLength: 18,
            },
          },
          {
            key: 'Name_Use_Contract__c',
            type: 'radio',
            hideExpression: (model: any) => model.Name_Use__c !== 'Yes',
            props: {
              label:
                'Are businesses required to sign a contract to use your name?',
              options: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ],
            },
          },
          {
            key: 'Target_Audience__c',
            type: 'textarea',
            props: {
              label: "How do you describe your organization's target audience?",
              maxLength: 1000,
            },
          },
          {
            key: 'Influencers__c',
            type: 'radio',
            props: {
              label: 'Do you work with influencers?',
              options: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ],
              required: true,
            },
          },
          {
            key: 'Influencers_Details__c',
            type: 'textarea',
            hideExpression: (model: any) => model.Influencers__c !== 'Yes',
            props: {
              label:
                'List all influencers with X, Facebook and Instagram handles',
              maxLength: 2000,
            },
          },
          {
            key: 'Followers_Facebook__c',
            type: 'select',
            props: {
              label: 'How many Facebook followers do you currently have?',
              options: [
                { label: 'Not Applicable', value: 'Not Applicable' },
                { label: '0-1000', value: '0-1000' },
                { label: '1000-5000', value: '1000-5000', score: 1 },
                { label: '5000-20000', value: '5000-20000', score: 2 },
                { label: '> 20,000', value: '> 20,000', score: 3 },
              ],
              placeholder: 'Select range...',
              required: true,
            },
          },
          {
            key: 'Followers_Instagram__c',
            type: 'select',
            props: {
              label: 'How many Instagram followers do you currently have?',
              options: [
                { label: 'Not Applicable', value: 'Not Applicable' },
                { label: '0-1000', value: '0-1000' },
                { label: '1000-5000', value: '1000-5000', score: 1 },
                { label: '5000-20000', value: '5000-20000', score: 2 },
                { label: '> 20,000', value: '> 20,000', score: 3 },
              ],
              placeholder: 'Select range...',
              required: true,
            },
          },
          {
            key: 'Followers_Twitter__c',
            type: 'select',
            props: {
              label: 'How many X followers do you currently have?',
              options: [
                { label: 'Not Applicable', value: 'Not Applicable' },
                { label: '0-1000', value: '0-1000' },
                { label: '1000-5000', value: '1000-5000', score: 1 },
                { label: '>5000', value: '>5000', score: 2 },
              ],
              placeholder: 'Select range...',
              required: true,
            },
          },
          {
            key: 'Followers_LinkedIn__c',
            type: 'select',
            props: {
              label: 'How many LinkedIn followers do you currently have?',
              options: [
                { label: '0-500', value: '0-500' },
                { label: '500-1000', value: '500-1000', score: 1 },
                { label: '>1000', value: '>1000', score: 2 },
              ],
              placeholder: 'Select range...',
              required: true,
            },
          },
          {
            key: 'Followers_Newsletter__c',
            type: 'select',
            props: {
              label: 'How many newsletter subscribers do you currently have?',
              options: [
                { label: '0-1,000', value: '0-1,000' },
                {
                  label: '1,000-5,000',
                  value: '1,000-5,000',
                  score: 1,
                },
                {
                  label: '5,000-20,000',
                  value: '5,000-20,000',
                  score: 2,
                },
                { label: '> 20,000', value: '> 20,000', score: 3 },
                { label: 'Not Applicable', value: 'Not Applicable' },
              ],
              placeholder: 'Select range...',
              required: true,
            },
          },
          {
            key: 'Website_Visitors_Monthly__c',
            type: 'select',
            props: {
              label:
                'How many visitors does your website currently receive monthly?',
              options: [
                { label: '0-1,000', value: '0-1,000' },
                {
                  label: '1,000-5,000',
                  value: '1,000-5,000',
                  score: 1,
                },
                {
                  label: '5,000-15,000',
                  value: '5,000-15,000',
                  score: 2,
                },
                { label: '> 15,000', value: '> 15,000', score: 3 },
                { label: 'Not Applicable', value: 'Not Applicable' },
              ],
              placeholder: 'Select range...',
              required: true,
            },
          },
          {
            key: 'Corp_Support_Visibility_Social_Media__c',
            type: 'radio',
            props: {
              label:
                'Do you offer social media visibility opportunities to corporate supporters?',
              options: [
                { label: 'Yes', value: 'Yes', score: 3 },
                { label: 'No', value: 'No' },
              ],
              required: true,
            },
          },
          {
            key: 'Corp_Support_Co_Branding__c',
            type: 'radio',
            props: {
              label:
                'Do you offer co-branding opportunities to corporate supporters?',
              options: [
                { label: 'Yes', value: 'Yes', score: 3 },
                { label: 'No', value: 'No' },
              ],
              required: true,
            },
          },
          {
            key: 'Files.Sponsorship_Deck',
            type: 'file',
            props: { label: 'Sponsorship deck' },
          },
          {
            key: 'Corp_Support_Visibility_Website__c',
            type: 'radio',
            props: {
              label:
                'Do you offer website visibility opportunities to corporate supporters?',
              options: [
                { label: 'Yes', value: 'Yes', score: 2 },
                { label: 'No', value: 'No' },
              ],
              required: true,
            },
          },
          {
            key: 'Corp_Support_Visibility_Newsletter__c',
            type: 'radio',
            props: {
              label:
                'Do you offer newsletter visibility opportunities to corporate supporters?',
              options: [
                { label: 'Yes', value: 'Yes', score: 1 },
                { label: 'No', value: 'No' },
              ],
              required: true,
            },
          },
          {
            key: 'Corp_Support_Feature__c',
            type: 'radio',
            props: {
              label:
                'Are you willing to feature a corporate supporter’s brands/products? (for example, would you be willing to feature a supporter’s food/drink brand at events you hold?)',
              options: [
                { label: 'Yes', value: 'Yes', score: 1 },
                { label: 'No', value: 'No' },
              ],
              required: true,
            },
          },
          {
            key: 'Celebrated_Partnership__c',
            type: 'radio',
            props: {
              label:
                'Have you celebrated your 1% for the Planet partnership on your organizations website, social media, or newsletter?',
              options: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ],
              required: true,
            },
          },
        ],
      },
      {
        props: { label: 'Submit' },
        fieldGroup: [
          {
            key: 'Feedback__c',
            type: 'textarea',
            props: {
              label:
                'Feedback regarding this questionnaire and 1% for the Planet',
              maxLength: 32768,
            },
          },
        ],
      },
    ],
    props: {},
  },
];
