import { get, isString } from 'lodash';

import { isNewFile } from './new-file';

export type ExistingFileLiteral = {
  id: string;
  name: string;
  imageUrl?: string;
};

export class ExistingFile implements ExistingFileLiteral {
  readonly isNew = false;

  readonly id: string;
  readonly name: string;
  readonly imageUrl?: string;

  constructor(
    nameOrOptions: string | { id?: string; name: string; imageUrl?: string },
  ) {
    if (typeof nameOrOptions === 'string') {
      this.id = nameOrOptions;
      this.name = nameOrOptions;
    } else {
      this.id = nameOrOptions.id || nameOrOptions.name;
      this.name = nameOrOptions.name;
      this.imageUrl = nameOrOptions?.imageUrl;
    }
  }
}

export function isExistingFile(
  value: unknown,
): value is ExistingFile | ExistingFileLiteral {
  return (
    value instanceof ExistingFile ||
    (!isNewFile(value) && isString(get(value, 'name')))
  );
}
