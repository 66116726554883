import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatAnchor, MatButton } from '@angular/material/button';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';

import { environment } from '../../../environments/environment';
import { AppTitleService } from '../../app-title.service';
import { ProgressService } from '../../shared/progress/progress.service';
import { describeAuthError } from '../../utility/firebase-errors';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatAnchor,
    MatIcon,
    MatFormField,
    MatLabel,
    MatInput,
    MatButton,
    AsyncPipe,
  ],
})
export class ForgotPasswordComponent {
  @Output() readonly afterSendEmail = new EventEmitter<void>();
  @Output() readonly returnToSignIn = new EventEmitter<void>();

  readonly errorMessage$ = new BehaviorSubject<string | undefined>(undefined);
  readonly submitting$ = new BehaviorSubject<boolean>(false);

  readonly form = this.fb.nonNullable.group({
    email: ['', [Validators.email, Validators.required]],
  });

  constructor(
    private auth: AuthService,
    private appTitle: AppTitleService,
    private fb: FormBuilder,
    private progress: ProgressService,
    private snackbar: MatSnackBar,
  ) {}

  async signIn(): Promise<void> {
    if (this.form.invalid) {
      return;
    }

    this.errorMessage$.next(undefined);
    this.submitting$.next(true);

    const { email } = this.form.getRawValue();

    try {
      await this.auth.sendPasswordResetEmail(email, {
        url: `${environment.appUrl}/auth`,
      });
      this.snackbar.open('Please check your email for instructions.', 'Got It');
      this.afterSendEmail.next();
    } catch (error) {
      this.errorMessage$.next(describeAuthError(error));
    }

    this.submitting$.next(false);
  }
}
