import { EventEmitter, HostBinding } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
} from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatIcon } from '@angular/material/icon';
import {
  MatListItem,
  MatListItemIcon,
  MatListItemTitle,
  MatNavList,
} from '@angular/material/list';
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterLinkActive,
} from '@angular/router';

import { TimesPipe } from '../../../utility/times.pipe';

export interface NavItem {
  icon: string;
  label: string;
  link?: string[];
  items?: SubNavItem[];
  badge?: boolean;
}

export interface SubNavItem {
  icon?: string;
  label: string;
  link: string[];
  badge?: boolean;
  items?: undefined;
}

@Component({
  selector: 'app-nav-list',
  templateUrl: './nav-list.component.html',
  styleUrl: './nav-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatNavList,
    RouterLinkActive,
    MatListItem,
    MatIcon,
    MatListItemIcon,
    MatListItemTitle,
    MatExpansionPanel,
    RouterLink,
    TimesPipe,
  ],
})
export class NavListComponent {
  @Input() navItems?: NavItem[] | null;
  @Input() navigateToFirstSubItem = true;
  @Input() placeholderRows = 3;
  @Input() showIcons = false;
  @HostBinding('class.rounded') @Input() rounded = false;

  @Output() readonly clickLink = new EventEmitter<NavItem | SubNavItem>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  openNavItem(navItem: NavItem): void {
    if (this.navigateToFirstSubItem && navItem.items) {
      void this.router.navigate(navItem.items[0].link, {
        relativeTo: this.route,
      });
    }
  }
}
