import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { User } from '@angular/fire/auth';
import { MatButton } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import {
  MatActionList,
  MatListItem,
  MatListItemIcon,
  MatListItemTitle,
  MatNavList,
} from '@angular/material/list';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { MatSidenav } from '@angular/material/sidenav';
import { RouterLink, RouterLinkActive } from '@angular/router';

import { ApiMembership } from '@one-percent/shared';

import { CookieConsentService } from '../../cookie-consent.service';
import { NavService } from '../nav.service';
import { NavItem, NavListComponent } from '../nav-list/nav-list.component';
import {
  ResourceLink,
  ResourceMenuComponent,
} from '../resource-menu/resource-menu.component';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrl: './sidenav.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatButton,
    NavListComponent,
    MatNavList,
    MatDivider,
    MatListItem,
    RouterLink,
    MatIcon,
    MatListItemIcon,
    MatListItemTitle,
    RouterLinkActive,
    MatMenuTrigger,
    MatMenu,
    ResourceMenuComponent,
    AsyncPipe,
    MatActionList,
  ],
})
export class SidenavComponent {
  @Input() sidenav?: MatSidenav;
  @Input() authState?: User | null;
  @Input() selectedMembership?: ApiMembership | null;
  @Input() navItems?: NavItem[] | null;
  @Input() resources?: ResourceLink[] | null;

  constructor(
    readonly cookieConsent: CookieConsentService,
    readonly nav: NavService,
  ) {}
}
