<h2 mat-dialog-title>Change Password</h2>

@if (user) {
  <form [formGroup]="form" (submit)="changePassword()" novalidate>
    <mat-dialog-content>
      <input
        class="cdk-visually-hidden"
        autocomplete="username"
        tabindex="-1"
        [value]="user.email"
      />

      <div class="flex flex-col gap-4">
        <mat-form-field hideRequiredMarker>
          <mat-label>Current Password</mat-label>
          <input
            type="password"
            matInput
            formControlName="currentPassword"
            autocomplete="current-password"
          />
          @if (form.get('currentPassword')?.errors?.['incorrect']) {
            <mat-error>Incorrect password.</mat-error>
          }
        </mat-form-field>

        <mat-form-field hideRequiredMarker>
          <mat-label>New Password</mat-label>
          <input
            type="password"
            matInput
            formControlName="newPassword"
            autocomplete="new-password"
          />
          <mat-hint class="text-secondary">{{ passwordHint }}</mat-hint>
        </mat-form-field>

        @if (errorMessage) {
          <div class="text-warn">{{ errorMessage }}</div>
        }
      </div>
    </mat-dialog-content>

    <div mat-dialog-actions align="end">
      <button type="button" mat-button mat-dialog-close>Cancel</button>
      <button type="submit" mat-button color="primary" [disabled]="submitting">
        {{ submitting ? 'Saving…' : 'Save' }}
      </button>
    </div>
  </form>
}
