import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appTimes',
  standalone: true,
})
export class TimesPipe implements PipeTransform {
  transform(value: number): unknown[] {
    return new Array(value);
  }
}
