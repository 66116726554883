import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  AccountType,
  ApiAccountAutocompleteQuery,
  ApiAccountAutocompleteResults,
  ApiAccountDetails,
  ApiAccountInfo,
  ApiAccountProfile,
  ApiAccountProfileStub,
  ApiAccountSearchQuery,
  ApiAccountSearchResults,
  ApiMembership,
  ApiUpdateAccountArgs,
  ApiUpdateAccountResult,
} from '@one-percent/shared';
import { LatLng, locationToUrlValue } from '@one-percent/shared';

import { environment } from '../../../environments/environment';
import { omitUndefined } from '../../utility/omit-undefined';

export interface AccountAutocompleteOptions {
  accountType: AccountType;
}

export interface AccountSearchOptions {
  favoritedBy?: string | null;
  q?: string | null;
  accountType?: AccountType;
  location?: string | LatLng | null;
  impactArea?: string | null;
  pageSize?: number;
  page?: number;
  bounds?: google.maps.LatLngBounds;
}

@Injectable({ providedIn: 'root' })
export class AccountApiService {
  constructor(private http: HttpClient) {}

  autocomplete(
    term: string,
    options: AccountAutocompleteOptions,
  ): Observable<ApiAccountAutocompleteResults> {
    return this.http.get<ApiAccountAutocompleteResults>(
      `${environment.apiUrl}/autocomplete`,
      {
        params: omitUndefined({
          term,
          ...options,
        } satisfies ApiAccountAutocompleteQuery),
      },
    );
  }

  search(options: AccountSearchOptions): Observable<ApiAccountSearchResults> {
    return this.http.get<ApiAccountSearchResults>(
      `${environment.apiUrl}/search`,
      {
        params: omitUndefined({
          ...options,
          location: options.location && locationToUrlValue(options.location),
          bounds: options.bounds?.toUrlValue(),
        } as ApiAccountSearchQuery),
      },
    );
  }

  getMemberships(): Observable<ApiMembership[]> {
    return this.http.get<ApiMembership[]>(`${environment.apiUrl}/memberships`);
  }

  getProfile({ id }: { id: string }) {
    return this.http.get<ApiAccountProfile>(
      `${environment.apiUrl}/accounts/${id}`,
    );
  }

  getInfo({ id }: { id: string }): Observable<ApiAccountInfo> {
    return this.http.get<ApiAccountInfo>(
      `${environment.apiUrl}/accounts/${id}/info`,
    );
  }

  getDetails({ id }: { id: string }): Observable<ApiAccountDetails | null> {
    return this.http.get<ApiAccountDetails>(
      `${environment.apiUrl}/accounts/${id}/details`,
    );
  }

  getReferrals({ id }: { id: string }): Observable<ApiAccountProfileStub[]> {
    return this.http.get<ApiAccountProfileStub[]>(
      `${environment.apiUrl}/accounts/${id}/referrals`,
    );
  }

  update({
    id,
    ...args
  }: ApiUpdateAccountArgs): Observable<ApiUpdateAccountResult> {
    return this.http.put<ApiUpdateAccountResult>(
      `${environment.apiUrl}/accounts/${id}/details`,
      args,
    );
  }

  acceptAgreement({ accountId }: { accountId: string }): Observable<void> {
    return this.http.put<void>(
      `${environment.apiUrl}/accounts/${accountId}/accept-agreement`,
      {},
    );
  }
}
