<form class="auth-card" [formGroup]="form" (submit)="submit()">
  <h1 class="mat-headline-4">You’re Invited!</h1>

  <div class="auth-card__content">
    <div>
      Create a password for your account:
      <div class="mt-1 text-primary truncate">{{ email }}</div>
    </div>

    <mat-form-field hideRequiredMarker>
      <mat-label>Password</mat-label>
      <input
        type="password"
        autocomplete="new-password"
        matInput
        formControlName="password"
      />
      <mat-hint class="text-secondary">{{ passwordHint }}</mat-hint>
    </mat-form-field>

    <button
      type="submit"
      mat-raised-button
      color="primary"
      [disabled]="submitting$ | async"
    >
      {{ (submitting$ | async) ? 'Submitting…' : 'Set Password' }}
    </button>
  </div>

  <div class="auth-card__footer">
    <div class="text-warn">{{ errorMessage$ | async }}</div>
  </div>
</form>
