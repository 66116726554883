import { addDays, parseISO, subMonths } from 'date-fns';

import { ApiFiscalYear } from '@one-percent/shared';

import { FiscalYear } from './fiscal-year';

export function parseFiscalYear(raw: ApiFiscalYear): FiscalYear {
  const end = parseISO(raw.end);
  return {
    ...raw,
    year: end.getUTCFullYear(),
    start: addDays(subMonths(end, 12), 1),
    end,
    duesPaidAt: raw.duesPaidDate ? parseISO(raw.duesPaidDate) : undefined,
    certificationDueDate: raw.certificationDueDate
      ? parseISO(raw.certificationDueDate)
      : addDays(end, 120),
  };
}
