import {
  animate,
  animateChild,
  group,
  keyframes,
  query,
  sequence,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

// default size param is required by Angular animations,
// but is overridden by component `size` input
const size = 72;

const emptyBoxShadow = `inset 0 0 0`;
const fullBoxShadow = `inset 0 0 0 calc({{ size }}px / 2)`;

export const stateIconAnimations = [
  trigger('state', [
    state('loading', style({ boxShadow: emptyBoxShadow }), {
      params: { size },
    }),
    state('error, success', style({ boxShadow: fullBoxShadow }), {
      params: { size },
    }),
    transition(
      'void => error, void => success, loading => error, loading => success',
      [
        sequence([
          query('@strokeCircle', animateChild()),
          animate('.4s ease-in-out', style({ boxShadow: fullBoxShadow })),
          group([
            query('@strokeCheckmark', animateChild()),
            query('@strokeX', animateChild()),
            animate(
              '.3s ease-in-out',
              keyframes([
                style({ transform: 'none' }),
                style({ transform: 'scale3d(1.1, 1.1, 1)' }),
                style({ transform: 'none' }),
              ]),
            ),
          ]),
        ]),
      ],
    ),
    transition('error => loading, success => loading', [
      sequence([
        group([
          query('@strokeCheckmark', animateChild()),
          query('@strokeX', animateChild()),
        ]),
        animate('.4s ease-in-out', style({ boxShadow: emptyBoxShadow })),
      ]),
    ]),
    transition('error <=> success', [
      animate(
        '.3s ease-in-out',
        keyframes([
          style({ transform: 'none' }),
          style({ transform: 'scale3d(1.1, 1.1, 1)' }),
          style({ transform: 'none' }),
        ]),
      ),
    ]),
  ]),
  trigger('strokeCircle', [
    state('*', style({ strokeDashoffset: 166 })),
    state('loading', style({ strokeDashoffset: 80 })),
    state('error, success', style({ strokeDashoffset: 0 })),
    transition('* => error, * => success', [
      animate('0.6s cubic-bezier(0.65, 0, 0.45, 1)'),
    ]),
  ]),
  trigger('strokeCheckmark', [
    state('*', style({ strokeDashoffset: 48 })),
    state('success', style({ strokeDashoffset: 0 })),
    transition('void <=> success, loading <=> success', [
      animate('0.3s cubic-bezier(0.65, 0, 0.45, 1)'),
    ]),
  ]),
  trigger('strokeX', [
    state('*', style({ strokeDashoffset: 48 })),
    state('error', style({ strokeDashoffset: 0 })),
    transition('void <=> error, loading <=> error', [
      animate('0.3s cubic-bezier(0.65, 0, 0.45, 1)'),
    ]),
  ]),
];
