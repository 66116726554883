import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { User } from '@angular/fire/auth';
import {
  MatButton,
  MatIconAnchor,
  MatIconButton,
} from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatSidenav } from '@angular/material/sidenav';
import { MatToolbar } from '@angular/material/toolbar';
import { RouterLink } from '@angular/router';

import { ApiMembership } from '@one-percent/shared';

import { UserMenuComponent } from '../user-menu/user-menu.component';

@Component({
  selector: 'app-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrl: './mobile-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatToolbar,
    MatIconAnchor,
    MatIcon,
    RouterLink,
    MatButton,
    UserMenuComponent,
    MatIconButton,
  ],
})
export class MobileHeaderComponent {
  @Input() sidenav?: MatSidenav;
  @Input() authState?: User | null;
  @Input() memberships?: ApiMembership[] | null;
  @Input() selectedMembership?: ApiMembership | null;

  @Output() readonly selectedMembershipChange =
    new EventEmitter<ApiMembership>();
  @Output() readonly changePassword = new EventEmitter<void>();
  @Output() readonly signIn = new EventEmitter<void>();
  @Output() readonly signOut = new EventEmitter<void>();
}
