import { ErrorHandler, Injectable } from '@angular/core';
import { patch } from '@rx-angular/cdk/transformations';
import { RxState } from '@rx-angular/state';
import { isNil, omitBy } from 'lodash';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  map,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';

import {
  ApiAccountDetails,
  ApiMembership,
  ApiUpdateAccountArgs,
  ApiUpdateAccountResult,
} from '@one-percent/shared';

import { AuthService } from '../../auth/auth.service';
import { FileInputValue } from '../../shared/file/file-input/file-input.component';
import { replayWhen } from '../../utility/custom-operators';
import { resolveFileValue } from '../form/resolve-file-value';
import { AccountApiService } from './account-api.service';

interface State {
  all: ApiMembership[] | null;
  selected: ApiMembership | null;
  selectedDetails: ApiAccountDetails | null;
  error: unknown;
}

const ACCOUNT_ID_KEY = 'account_id';

@Injectable({ providedIn: 'root' })
export class AccountService extends RxState<State> {
  readonly all$ = this.select('all');
  readonly selected$ = this.select('selected');
  readonly selectedId$ = this.select('selected').pipe(
    map((selected) => selected?.id),
    distinctUntilChanged(),
    shareReplay(1),
  );
  readonly selectedDetails$ = this.select('selectedDetails');
  readonly error$ = this.select('error');

  private accountIdSubject = new BehaviorSubject<string | null>(
    localStorage?.getItem(ACCOUNT_ID_KEY) ?? null,
  );

  private updateDetailsSubject = new Subject<Partial<ApiAccountDetails>>();
  private reloadSubject = new Subject<void>();

  constructor(
    private auth: AuthService,
    private errorHandler: ErrorHandler,
    private accountApi: AccountApiService,
  ) {
    super();

    this.connect(
      'all',
      this.auth.authState$.pipe(
        map((user) => !!user),
        distinctUntilChanged(),
        replayWhen(this.reloadSubject),
        tap(() => {
          this.set('error', () => null);
        }),
        switchMap((loggedIn) =>
          loggedIn
            ? this.accountApi.getMemberships().pipe(
                startWith(null),
                catchError((error) => {
                  this.errorHandler.handleError(error);
                  this.set('error', () => error);
                  return of([]);
                }),
              )
            : of(null),
        ),
      ),
    );

    this.connect(
      'selected',
      combineLatest([this.select('all'), this.accountIdSubject]).pipe(
        map(([memberships, accountId]) =>
          memberships
            ? memberships.find((m) => m.id === accountId) || memberships[0]
            : null,
        ),
      ),
    );

    this.connect(
      'selectedDetails',
      this.select('selected').pipe(
        tap(() => {
          this.set('error', () => null);
        }),
        switchMap((membership) =>
          membership
            ? this.accountApi.getDetails({ id: membership.id }).pipe(
                startWith(null),
                catchError((error) => {
                  this.errorHandler.handleError(error);
                  this.set('error', () => error);
                  return of(null);
                }),
              )
            : of(null),
        ),
      ),
    );

    this.connect(
      'selectedDetails',
      this.updateDetailsSubject,
      ({ selectedDetails }, changes) =>
        selectedDetails && patch(selectedDetails, changes),
    );
  }

  selectAccount(id: string): void {
    localStorage?.setItem(ACCOUNT_ID_KEY, id);
    this.accountIdSubject.next(id);
  }

  update({
    logo,
    images,
    ...args
  }: Omit<ApiUpdateAccountArgs, 'logo' | 'images'> & {
    logo?: FileInputValue | null;
    images?: FileInputValue | null;
  }): Observable<ApiUpdateAccountResult> {
    this.optimisticUpdate({
      ...omitBy(args, isNil),
      // if logo was removed we can optimistically update it,
      // otherwise will have to wait for API result to get new URL
      ...(logo?.length === 0 ? { logoUrl: undefined } : {}),
    });
    return combineLatest([
      logo ? resolveFileValue(logo) : of(undefined),
      images ? resolveFileValue(images) : of(undefined),
    ]).pipe(
      switchMap(([logoValues, imageValues]) =>
        this.accountApi.update({
          ...args,
          logo: logo?.length === 0 ? null : logoValues?.[0],
          images: imageValues,
        }),
      ),
      tap((result) => {
        this.optimisticUpdate(result);
      }),
    );
  }

  acceptAgreement(args: { accountId: string }): Observable<void> {
    this.optimisticUpdate({
      signedAgreementAt: new Date().toISOString(),
    });
    return this.accountApi.acceptAgreement(args);
  }

  reload() {
    this.reloadSubject.next();
  }

  optimisticUpdate(args: Partial<ApiAccountDetails>): void {
    this.updateDetailsSubject.next(args);
  }
}
