import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { MessageComponent } from '../../shared/message/message.component';
import { ProgressService } from '../../shared/progress/progress.service';
import { describeAuthError } from '../../utility/firebase-errors';
import { AuthService } from '../auth.service';

@Component({
  templateUrl: './verify-email.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MessageComponent],
})
export class VerifyEmailComponent implements OnInit, OnDestroy {
  private subscription?: Subscription;

  constructor(
    private auth: AuthService,
    private progress: ProgressService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    // redirect once the user verifies their email
    // NOTE: doesn't fire if the user verifies their email after this page is loaded,
    // but will fire after the user refreshes this page at least.
    this.subscription = this.auth.authState$
      .pipe(filter((user) => !!user?.emailVerified))
      .subscribe(() => {
        const { returnTo } = this.route.snapshot.queryParams;
        void this.router.navigateByUrl(
          typeof returnTo === 'string' ? returnTo : '/account',
        );
      });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  async resendVerificationEmail(): Promise<void> {
    const snackbar = this.progress.show({
      message: 'Resending verification email',
    });
    try {
      if (this.auth.currentUser) {
        await this.auth.sendEmailVerification(this.auth.currentUser, {
          url: environment.appUrl,
        });
        snackbar.instance.dismissWithSuccess();
      } else {
        this.progress.hide();
        await this.router.navigate(['/auth']);
      }
    } catch (error) {
      snackbar.instance.dismissWithError(describeAuthError(error));
    }
  }
}
