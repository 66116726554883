import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AppTitleService } from '../app-title.service';
import { AccountService } from '../data-access/account/account.service';
import { MaintenanceService } from './maintenance.service';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrl: './maintenance.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe],
})
export class MaintenanceComponent implements OnInit {
  readonly status$ = this.maintenance.status$.pipe(
    tap((status) => {
      if (!status) {
        void this.returnToApp();
      }
    }),
  );

  constructor(
    private accountService: AccountService,
    private appTitle: AppTitleService,
    private maintenance: MaintenanceService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit() {
    this.appTitle.setTitle('Maintenance');
  }

  private async returnToApp() {
    // reload accounts if necessary
    const error = await firstValueFrom(this.accountService.error$);
    if (error) {
      this.accountService.reload();
    }

    const uri: unknown = this.route.snapshot.queryParams['returnTo'];
    void this.router.navigateByUrl(
      typeof uri === 'string' ? decodeURI(uri) : '/account',
    );
  }
}
