<mat-sidenav-container>
  <mat-sidenav #sidenav>
    <app-sidenav
      [authState]="authState$ | async"
      [sidenav]="sidenav"
      [navItems]="navItems$ | async"
      [resources]="resources$ | async"
      [selectedMembership]="selectedMembership$ | async"
    />
  </mat-sidenav>
  <mat-sidenav-content
    appScrollTopOnNav
    cdkVirtualScrollingElement
    class="contain-none"
  >
    <div
      class="content"
      [class.content--has-banner]="
        (isImpersonating$ | async) || (showSlackBanner$ | async)
      "
    >
      @if (isImpersonating$ | async) {
        <div class="site-banner site-banner--dev">
          <div>
            Signed in as <strong>{{ (authState$ | async)?.email }}</strong>
          </div>
          <a class="site-banner__link" (click)="signOut()">Sign out</a>
        </div>
      } @else if (showSlackBanner$ | async) {
        <a
          class="site-banner"
          href="https://membersupport.onepercentfortheplanet.org/get-involved/slack-for-members"
          target="_blank"
          matRipple
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            class="site-banner__icon"
          >
            <path
              fill="currentColor"
              d="M126.12 315.1A47.06 47.06 0 1 1 79.06 268h47.06ZM149.84 315.1a47.06 47.06 0 0 1 94.12 0v117.84a47.06 47.06 0 1 1-94.12 0ZM196.9 126.12A47.06 47.06 0 1 1 244 79.06v47.06ZM196.9 149.84a47.06 47.06 0 0 1 0 94.12H79.06a47.06 47.06 0 0 1 0-94.12ZM385.88 196.9a47.06 47.06 0 1 1 47.06 47.1h-47.06ZM362.16 196.9a47.06 47.06 0 0 1-94.12 0V79.06a47.06 47.06 0 1 1 94.12 0ZM315.1 385.88a47.06 47.06 0 1 1-47.1 47.06v-47.06ZM315.1 362.16a47.06 47.06 0 0 1 0-94.12h117.84a47.06 47.06 0 1 1 0 94.12Z"
            />
          </svg>
          <div>
            <strong>Stay connected!</strong>
            Join <span class="sm:hidden">us</span>
            <span class="max-sm:hidden">1% for the Planet</span> on Slack
          </div>

          <button
            type="button"
            mat-icon-button
            class="site-banner__dismiss"
            (click)="$event.preventDefault(); dismissSlackBanner()"
          >
            <mat-icon>close</mat-icon>
          </button>
        </a>
      }
      <app-mobile-header
        class="sm:hidden"
        [sidenav]="sidenav"
        [authState]="authState$ | async"
        [memberships]="memberships$ | async"
        [selectedMembership]="selectedMembership$ | async"
        (selectedMembershipChange)="selectAccount($event)"
        (changePassword)="openChangePasswordDialog()"
        (signIn)="signIn()"
        (signOut)="signOut()"
      />
      <app-header
        class="hidden sm:block"
        [authState]="authState$ | async"
        [memberships]="memberships$ | async"
        [resources]="resources$ | async"
        [selectedMembership]="selectedMembership$ | async"
        (selectedMembershipChange)="selectAccount($event)"
        (changePassword)="openChangePasswordDialog()"
        (signIn)="signIn()"
        (signOut)="signOut()"
      />

      <div class="content-main">
        <router-outlet />
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
