import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButton } from '@angular/material/button';
import {
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import {
  MatError,
  MatFormField,
  MatHint,
  MatLabel,
} from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';

import { passwordHint, passwordMinLength } from '../../app.constants';
import { ProgressSnackbar } from '../../shared/progress/progress.snackbar';
import {
  describeAuthError,
  isFirebaseError,
} from '../../utility/firebase-errors';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password.dialog.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatDialogTitle,
    FormsModule,
    ReactiveFormsModule,
    MatDialogContent,
    MatFormField,
    MatLabel,
    MatInput,
    MatError,
    MatHint,
    MatDialogActions,
    MatButton,
    MatDialogClose,
  ],
})
export class ChangePasswordDialog {
  readonly passwordHint = passwordHint;
  readonly form = this.fb.nonNullable.group({
    currentPassword: ['', Validators.required],
    newPassword: [
      '',
      [Validators.required, Validators.minLength(passwordMinLength)],
    ],
  });

  errorMessage?: string | null;
  submitting = false;
  user = this.auth.currentUser;

  constructor(
    private auth: AuthService,
    private cd: ChangeDetectorRef,
    private fb: FormBuilder,
    private ref: MatDialogRef<ChangePasswordDialog>,
    private snackbar: MatSnackBar,
  ) {}

  static open(dialog: MatDialog): MatDialogRef<ChangePasswordDialog> {
    return dialog.open<ChangePasswordDialog>(ChangePasswordDialog, {
      disableClose: true,
      width: '360px',
    });
  }

  async changePassword(): Promise<void> {
    if (!this.user?.email || this.form.invalid) {
      return;
    }

    this.errorMessage = null;
    this.submitting = true;

    const { currentPassword, newPassword } = this.form.getRawValue();

    try {
      await this.auth.updatePassword(this.user, currentPassword, newPassword);
      this.ref.close();

      ProgressSnackbar.showSuccess(this.snackbar, 'Password changed');
    } catch (error) {
      // When re-authenticating, Firebase can throw a couple errors,
      // both of which effectively mean the current password is incorrect
      if (
        isFirebaseError(error) &&
        [
          'auth/invalid-credential',
          'auth/wrong-password',
          'auth/too-many-requests',
        ].includes(error.code)
      ) {
        this.form.get('currentPassword')?.setErrors({ incorrect: true });
      } else {
        this.errorMessage = describeAuthError(error);
      }

      this.submitting = false;
      this.cd.markForCheck();
    }
  }
}
