<div class="layout-container">
  <app-message
    icon="mail_outline"
    title="Verify your email"
    message="Please check your email for verification instructions."
    actionLabel="Resend verification email"
    [border]="false"
    (action)="resendVerificationEmail()"
  />
</div>
