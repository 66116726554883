<div class="message">
  <div class="max-w-prose">
    <div class="mat-headline-4">
      {{ (status$ | async)?.message }}
    </div>
    <p class="mat-subtitle-2">
      Thanks for your patience.<br />You’ll be redirected as soon as we’re back
      online.
    </p>
  </div>
</div>
