import {
  provideHttpClient,
  withInterceptors,
  withJsonpSupport,
} from '@angular/common/http';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
} from '@angular/core';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import {
  browserLocalPersistence,
  indexedDBLocalPersistence,
  initializeAuth,
  provideAuth,
} from '@angular/fire/auth';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, Router, withInMemoryScrolling } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { provideQuillConfig } from 'ngx-quill';

import { noop } from '@one-percent/shared';

import { environment } from '../environments/environment';
import { routes } from './app.routes';
import { AppErrorHandler } from './app-error-handler';
import { authInterceptor } from './auth/auth.interceptor';
import { maintenanceInterceptor } from './maintenance/maintenance.interceptor';
import { NavErrorHandler } from './nav-error-handler';
import { provideMaterialConfig } from './provide-material-config';

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideHttpClient(
      withJsonpSupport(),
      withInterceptors([authInterceptor, maintenanceInterceptor]),
    ),
    provideRouter(
      routes,
      withInMemoryScrolling({ scrollPositionRestoration: 'top' }),
    ),
    provideMaterialConfig(),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() =>
      initializeAuth(getApp(), {
        // https://firebase.google.com/docs/auth/web/custom-dependencies
        persistence: [indexedDBLocalPersistence, browserLocalPersistence],
        popupRedirectResolver: undefined, // na because we don't support sign in with popup/redirect
      }),
    ),
    provideQuillConfig({ sanitize: true }),
    { provide: ErrorHandler, useClass: AppErrorHandler },
    { provide: Sentry.TraceService, deps: [Router] },
    // ensure these services are initialized
    {
      provide: APP_INITIALIZER,
      useFactory: () => noop,
      deps: [NavErrorHandler, Sentry.TraceService],
      multi: true,
    },
  ],
};
