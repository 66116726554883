import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class StatusApiService {
  constructor(private http: HttpClient) {}

  checkStatus() {
    return this.http.get<void>(`${environment.apiUrl}/status`).pipe(
      map(() => true),
      catchError(() => of(false)),
    );
  }
}
