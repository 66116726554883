import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatAnchor } from '@angular/material/button';
import { Router, RouterLink } from '@angular/router';

import { AppTitleService } from '../app-title.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrl: './not-found.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatAnchor, RouterLink],
})
export class NotFoundComponent implements OnInit {
  constructor(
    private appTitle: AppTitleService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.appTitle.setTitle('404');
  }

  returnHome(): void {
    void this.router.navigateByUrl('/');
  }
}
