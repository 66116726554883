import { Component, Inject } from '@angular/core';
import { MatButton } from '@angular/material/button';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBar,
  MatSnackBarAction,
  MatSnackBarConfig,
  MatSnackBarRef,
} from '@angular/material/snack-bar';
import { RouterLink } from '@angular/router';

import { describeError } from '../../utility/describe-error';
import { State, StateIconComponent } from '../state-icon/state-icon.component';

export interface ProgressSnackbarData {
  message?: string;
  completeMessage?: string;
  completeAction?: ActionData;
  errorMessage?: string;
  state?: State;
}

interface ActionData {
  label: string;
  link: unknown[];
}

const ERROR_DURATION = 5_000; // 5 seconds
const SUCCESS_DURATION = 2_000; // 2 seconds
const SUCCESS_WITH_ACTION_DURATION = 10_000; // 10 seconds

@Component({
  selector: 'app-progress',
  templateUrl: './progress.snackbar.html',
  styleUrl: './progress.snackbar.scss',
  standalone: true,
  imports: [StateIconComponent, MatButton, RouterLink, MatSnackBarAction],
})
export class ProgressSnackbar {
  /** @internal */
  loadingMessage = this.data?.message || 'Loading';
  /** @internal */
  completeMessage = this.data?.completeMessage || 'Done';
  /** @internal */
  completeAction = this.data?.completeAction;
  /** @internal */
  errorMessage = this.data?.errorMessage || 'Something went wrong!';
  /** @internal */
  state: State = this.data?.state || 'loading';

  constructor(
    readonly ref: MatSnackBarRef<ProgressSnackbar>,
    @Inject(MAT_SNACK_BAR_DATA) private data?: ProgressSnackbarData,
  ) {}

  static open(
    snackbar: MatSnackBar,
    data?: ProgressSnackbarData,
    config?: MatSnackBarConfig,
  ): MatSnackBarRef<ProgressSnackbar> {
    return snackbar.openFromComponent(ProgressSnackbar, { ...config, data });
  }

  static showError(snackbar: MatSnackBar, error?: unknown) {
    ProgressSnackbar.open(
      snackbar,
      {
        state: 'error',
        errorMessage: error ? describeError(error).message : undefined,
      },
      { duration: ERROR_DURATION },
    );
  }

  static showSuccess(
    snackbar: MatSnackBar,
    message: string,
    action?: ActionData,
  ) {
    ProgressSnackbar.open(
      snackbar,
      { state: 'success', completeMessage: message, completeAction: action },
      { duration: action ? SUCCESS_WITH_ACTION_DURATION : SUCCESS_DURATION },
    );
  }

  dismissWithError(message: string): void {
    this.errorMessage = message;
    this.state = 'error';
    // give time for complete animation & error message to be read before dismissing
    setTimeout(() => this.ref.dismiss(), ERROR_DURATION);
  }

  dismissWithSuccess(): void {
    this.state = 'success';
    // give time for complete animation to show before dismissing
    setTimeout(
      () => this.ref.dismiss(),
      this.completeAction ? SUCCESS_WITH_ACTION_DURATION : SUCCESS_DURATION,
    );
  }
}
