import { Environment } from './environment.model';

export const environment: Environment = {
  production: true,
  appUrl: process.env['APP_URL']!,
  apiUrl: process.env['API_URL']!,
  onePercentAccountId: '0010G00001xIlUCQA0',
  investmentAccountId: '001UZ000003MQwcYAG',
  suggestNonprofitFormId: 'b849d069-40ca-4d72-8896-669e266369e6',
  suggestNonprofitFranceFormId: '3f4ddbf5-4923-4b95-a3c5-1703fefc7f77',
  firebase: {
    apiKey: 'AIzaSyBrZsfLA4UMzId2RE-AFp6aTWKPtXf1Fkw',
    authDomain: 'one-percent-development.firebaseapp.com',
    databaseURL: 'https://one-percent-development.firebaseio.com',
    projectId: 'one-percent-development',
    storageBucket: 'one-percent-development.appspot.com',
    messagingSenderId: '494381089631',
  },
  googleMapsApiKey: 'AIzaSyBrZsfLA4UMzId2RE-AFp6aTWKPtXf1Fkw',
  mixpanelToken: '3f2463960e14942509ac8e5525e8c896',
  sentry: {
    dsn: 'https://c0e8d63249d84661a0b5c5419a965aee@o366870.ingest.sentry.io/5653776',
    environment: 'production',
  },
};
