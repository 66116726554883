import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';

import { ApiMembership, isNotNil } from '@one-percent/shared';

import { AccountService } from '../data-access/account/account.service';
import { NavItem } from '../shared/nav/nav-list/nav-list.component';
import { ResourceLink } from '../shared/nav/resource-menu/resource-menu.component';
import { accountNavItems } from './account-nav-items';
import { accountResources } from './account-resources';

@Injectable({ providedIn: 'root' })
export class AccountNavService {
  readonly items$ = combineLatest([
    this.accountService.selected$.pipe(filter(isNotNil)),
    this.accountService.selectedDetails$,
  ]).pipe(
    map(([membership, account]): NavItem[] =>
      accountNavItems
        .filter((navItem) => navItem.show?.({ membership, account }) !== false)
        .map((navItem) => ({
          ...navItem,
          badge: navItem.badge?.({ membership, account }),
        })),
    ),
    shareReplay(1),
  );

  readonly resources$: Observable<ResourceLink[] | undefined> =
    this.accountService.all$.pipe(
      map((memberships) =>
        memberships ? this.getResources(memberships) : undefined,
      ),
    );

  constructor(private accountService: AccountService) {}

  private getResources(memberships: ApiMembership[]) {
    // don't return any resources if all memberships are in France
    if (!memberships.some((m) => m.country?.toLowerCase() !== 'france')) {
      return [];
    }

    const accountTypes = memberships.map((m) => m.type);
    return accountResources.filter(
      (resource) => resource.show?.(accountTypes) !== false,
    );
  }
}
