<div class="content" [ngClass]="'content--' + mode">
  @if (icon) {
    <mat-icon [class.large]="size === 'large'">{{ icon }}</mat-icon>
  }

  @if (!icon && type !== 'message') {
    <app-state-icon [state]="type" [size]="size === 'large' ? 96 : 72" />
  }

  <div class="inner-content">
    @if (title) {
      <div class="title">{{ title }}</div>
    }
    @if (html) {
      <div class="html" [innerHTML]="html"></div>
    }
    @if (message) {
      <div class="message">{{ message }}</div>
    }
    @if (actionLabel) {
      <button mat-stroked-button (click)="action.emit()">
        {{ actionLabel }}
      </button>
    }
  </div>
</div>
