export interface LatLng {
  lat: number;
  lng: number;
}

export interface LatLngBounds {
  northeast: LatLng;
  southwest: LatLng;
}

export function latLngFromUrlValue(latLng: string): LatLng | undefined {
  const parts = latLng.split(',').map(parseFloat);
  if (parts.length !== 2 || parts.some(isNaN)) {
    return undefined;
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return { lat: parts[0]!, lng: parts[1]! };
}

export function latLngToUrlValue({ lat, lng }: LatLng): string {
  return [lat, lng].join(',');
}

export function locationToUrlValue(location: string | LatLng): string {
  return typeof location === 'string' ? location : latLngToUrlValue(location);
}

export function locationFromUrlValue(location: string): LatLng | string {
  return latLngFromUrlValue(location) ?? location;
}

/**
 * Parses bounds from URL value.
 * See https://developers.google.com/maps/documentation/javascript/reference/coordinates#LatLngBounds.toUrlValue
 */
export function boundsFromUrlValue(bounds: string): LatLngBounds | undefined {
  const parts = bounds.split(',').map(parseFloat);
  if (parts.length !== 4 || parts.some(isNaN)) {
    return undefined;
  }

  return {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    southwest: { lat: parts[0]!, lng: parts[1]! },
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    northeast: { lat: parts[2]!, lng: parts[3]! },
  };
}
