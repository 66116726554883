<button mat-button class="trigger rounded-full" [matMenuTriggerFor]="menu">
  <div class="trigger__content">
    <app-avatar
      [name]="isLoadingMemberships ? undefined : selectedMembership?.name"
      [imageUrl]="selectedMembership?.logoUrl"
    />
    <div class="min-w-0 hidden sm:block">
      <div class="truncate">
        @if (selectedMembership || !isLoadingMemberships) {
          {{ displayName }}
        } @else {
          <span
            class="loading-placeholder loading-placeholder--inline w-9"
          ></span>
        }
      </div>
      @if (
        selectedMembership &&
        selectedMembership.name !== selectedMembership.currentUser.name
      ) {
        <div class="mat-caption text-secondary truncate">
          {{ selectedMembership.name }}
        </div>
      }
    </div>
    <mat-icon class="text-secondary ms-auto me-1 hidden sm:block"
      >expand_more</mat-icon
    >
  </div>
</button>
<mat-menu #menu="matMenu" xPosition="before">
  <div class="relative -mt-2">
    <div class="max-h-80 overflow-auto py-2">
      @for (membership of memberships; track membership.id) {
        <button
          mat-menu-item
          [class.active]="membership.id === selectedMembership?.id"
          (click)="selectedMembershipChange.emit(membership)"
        >
          <app-avatar
            matMenuItemIcon
            class="me-4"
            [name]="membership.name"
            [imageUrl]="membership.logoUrl"
            size="small"
          />

          {{ membership.name }}
        </button>
      }
    </div>
    <div class="absolute top-0 w-full h-2 bg-gradient-to-b from-white"></div>
    <div class="absolute bottom-0 w-full h-2 bg-gradient-to-t from-white"></div>
  </div>

  @if (!memberships) {
    <a mat-menu-item disabled>
      <span class="loading-placeholder"></span>
    </a>
  }

  @if (memberships?.length) {
    <mat-divider />
    <a mat-menu-item routerLink="/favorites">
      <mat-icon>favorite</mat-icon>
      Favorites
    </a>
  }

  @if (memberships?.length !== 0) {
    <mat-divider />
  }

  @if (showLegal) {
    <a
      mat-menu-item
      href="https://onepercentfortheplanet.org/privacy-policy"
      target="_blank"
    >
      <mat-icon>policy</mat-icon>
      Privacy policy
    </a>
    <a
      mat-menu-item
      href="https://onepercentfortheplanet.org/terms-of-use"
      target="_blank"
    >
      <mat-icon>check_circle</mat-icon>
      Terms of use
    </a>
    <button mat-menu-item (click)="cookieConsent.openPreferences()">
      <mat-icon>cookie</mat-icon>
      Cookie preferences
    </button>

    <mat-divider />
  }

  <button mat-menu-item (click)="changePassword.emit()">
    <mat-icon>lock</mat-icon>
    Change password
  </button>
  <button mat-menu-item (click)="signOut.emit()">
    <mat-icon>logout</mat-icon>
    Sign Out
  </button>
</mat-menu>
