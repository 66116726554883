<mat-nav-list [class.rounded]="rounded">
  @for (navItem of navItems; track navItem.label) {
    @if (navItem.items) {
      <div routerLinkActive #rla="routerLinkActive">
        <mat-list-item
          class="has-children"
          [class.active]="rla.isActive"
          (click)="panel.toggle(); !rla.isActive && openNavItem(navItem)"
        >
          @if (showIcons) {
            <mat-icon matListItemIcon>{{ navItem.icon }}</mat-icon>
          }
          <span matListItemTitle>
            {{ navItem.label }}
            @if (navItem.badge) {
              <span class="badge"></span>
            }
          </span>
        </mat-list-item>

        <mat-expansion-panel #panel [expanded]="rla.isActive">
          @for (subItem of navItem.items; track subItem.label) {
            <a
              class="child"
              mat-list-item
              [routerLink]="subItem.link"
              routerLinkActive="active"
              (click)="clickLink.emit(subItem)"
            >
              {{ subItem.label }}
            </a>
          }
        </mat-expansion-panel>
      </div>
    } @else {
      <a
        mat-list-item
        [routerLink]="navItem.link"
        [routerLinkActive]="'active'"
        (click)="clickLink.emit(navItem)"
      >
        @if (showIcons) {
          <mat-icon matListItemIcon>{{ navItem.icon }}</mat-icon>
        }
        <span matListItemTitle>
          {{ navItem.label }}
          @if (navItem.badge) {
            <span class="badge"></span>
          }
        </span>
      </a>
    }
  }

  @if (!navItems) {
    @for (i of placeholderRows | appTimes; track i) {
      <a mat-list-item>
        <span class="loading-placeholder"></span>
      </a>
    }
  }
</mat-nav-list>
