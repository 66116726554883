import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { User } from '@angular/fire/auth';
import { MatAnchor, MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatToolbar } from '@angular/material/toolbar';
import { RouterLink, RouterLinkActive } from '@angular/router';

import { ApiMembership } from '@one-percent/shared';

import { CookieConsentService } from '../../cookie-consent.service';
import { fadeInAnimation } from '../fade-in.animation';
import { NavService } from '../nav.service';
import {
  ResourceLink,
  ResourceMenuComponent,
} from '../resource-menu/resource-menu.component';
import { UserMenuComponent } from '../user-menu/user-menu.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  animations: [fadeInAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatToolbar,
    MatAnchor,
    RouterLink,
    MatButton,
    MatMenuTrigger,
    MatMenu,
    ResourceMenuComponent,
    RouterLinkActive,
    UserMenuComponent,
    MatIconButton,
    MatIcon,
    MatMenuItem,
    AsyncPipe,
  ],
})
export class HeaderComponent {
  @Input() authState?: User | null;
  @Input() memberships?: ApiMembership[] | null;
  @Input() selectedMembership?: ApiMembership | null;
  @Input() resources?: ResourceLink[] | null;

  @Output() readonly selectedMembershipChange =
    new EventEmitter<ApiMembership>();
  @Output() readonly changePassword = new EventEmitter<void>();
  @Output() readonly signIn = new EventEmitter<void>();
  @Output() readonly signOut = new EventEmitter<void>();

  /** @internal */
  get hideNav() {
    // hide nav while auth state is unknown
    return this.authState === undefined;
  }

  constructor(
    readonly cookieConsent: CookieConsentService,
    readonly nav: NavService,
  ) {}
}
